import React, { Component } from "react";
import MetaTags from "react-meta-tags";
import { Container } from "reactstrap";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import { Row, Col, Card, CardBody, CardTitle, CardHeader, Button } from "reactstrap";
import { AvForm, AvField, AvRadioGroup, AvRadio } from "availity-reactstrap-validation";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import { post, get } from "src/helpers/api_helper";

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { connect } from "react-redux";

interface Params extends RouteComponentProps {
    units: Array<any>;
    taxes: Array<any>;
}

type State = {
    id: string;
    name: string;
    description: string;
    rate: number;
    unit_id: number;
    tax_id: number;
    units: Array<any>;
    taxes: Array<any>;
    type: string;
    hsn_or_sac: string;
    permissions: Array<any>;
    loading: boolean;
    isProgress: number;
};

class ProductCreate extends Component<Params, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            id: "0",
            name: "",
            description: "",
            unit_id: 0,
            tax_id: 0,
            rate: 0,
            units: [],
            taxes: [],
            type: "product",
            hsn_or_sac: "",
            permissions: [],
            loading: true,
            isProgress: 0
        };
    }

    resetForm = () => {

        this.props.history.push("/products");
    }
    onChangehandler = (e: any) => {
        let name = e.target.name;
        let value = e.target.value;
        let data: any = {};
        data[name] = value;
        this.setState(data);
    }
    handleValidSubmit = (event: any, values: any) => {
        this.setState({ isProgress: 1 });
        values["id"] = this.state.id;

        const resp = post(process.env.REACT_APP_API_URL + '/api/product_save', values);
        this.props.history.push("/products");
        this.setState({ isProgress: 0 });
    }

    async componentDidMount() {
        try {

            let urls = [
                process.env.REACT_APP_API_URL + `/api/permissions/6`               
            ];

            Promise.all(urls.map((url) => get(url))).then(([respPer]) => {
                this.setState({ permissions: respPer.data });
                const permission = this.state.permissions.find(({ action, is_active }) => action == "create" && is_active == 1);

                if (!permission) {
                    this.props.history.push("/products");
                }

                this.setState({ units: this.props.units, taxes: this.props.taxes });
            })

        } catch (err) {
            // Handle Error Here
            console.error(err);
        }
        this.setState({ loading: false });
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Products</title>
                    </MetaTags>
                    <div className="container-fluid">
                        <Breadcrumbs title="Create Product" breadcrumbItem="Create Product" />

                        <Row>
                            <Col className="col-12">
                                <Card>

                                    <CardHeader></CardHeader>

                                    <CardBody>

                                        <AvForm onValidSubmit={this.handleValidSubmit}>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="mb-3">
                                                        {this.state.loading ?
                                                            <><Skeleton width={100} /><Skeleton height={37} /></> :
                                                            <>
                                                                <label htmlFor="progresspill-firstname-input">
                                                                    Product name
                                                                </label>
                                                                <AvField
                                                                    name="name"
                                                                    type="text"
                                                                    placeholder="Enter Product name"
                                                                    errorMessage="Enter Product name"
                                                                    value={this.state.name}
                                                                    onChange={this.onChangehandler}
                                                                    validate={{ required: { value: true } }}
                                                                />
                                                            </>}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="mb-3">
                                                        {this.state.loading ?
                                                            <><Skeleton width={100} /><Skeleton height={37} /></> :
                                                            <>
                                                                <label htmlFor="progresspill-lastname-input">
                                                                    Product Type
                                                                </label>
                                                                <AvRadioGroup inline name="type" onChange={this.onChangehandler} required value={this.state.type}>
                                                                    <AvRadio label="Product" value="product" />
                                                                    <AvRadio label="Service" value="service" />
                                                                </AvRadioGroup>
                                                            </>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="mb-3">
                                                        {this.state.loading ?
                                                            <><Skeleton width={100} /><Skeleton height={37} /></> :
                                                            <>
                                                                <label htmlFor="progresspill-firstname-input">
                                                                    {this.state.type == "service" ? "SAC" : "HSN"}
                                                                </label>
                                                                <AvField
                                                                    name="hsn_or_sac"
                                                                    type="text"
                                                                    placeholder={this.state.type == "service" ? "Enter SAC" : "Enter HSN"}
                                                                    value={this.state.hsn_or_sac}
                                                                    onChange={this.onChangehandler}
                                                                />
                                                            </>}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="mb-3">
                                                        {this.state.loading ?
                                                            <><Skeleton width={100} /><Skeleton height={37} /></> :
                                                            <>
                                                                <AvField type="select" name="tax_id" label="GST Tax Rate" errorMessage="Choose Tax"
                                                                    //validate={{ required: { value: true } }} 
                                                                    value={this.state.tax_id} onChange={this.onChangehandler} >
                                                                    <option value="" key="" >--Select--</option>
                                                                    {this.state.taxes.map((tax) => (
                                                                        <option value={tax.id} key={tax.id} >{tax.tax_name}</option>
                                                                    ))}
                                                                </AvField>
                                                            </>}
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="mb-3">
                                                        {this.state.loading ?
                                                            <><Skeleton width={100} /><Skeleton height={37} /></> :
                                                            <>
                                                                <label htmlFor="progresspill-lastname-input">
                                                                    Rate
                                                                </label>
                                                                <AvField
                                                                    name="rate"
                                                                    type="number"
                                                                    placeholder="Enter rate"
                                                                    errorMessage="Enter rate"
                                                                    value={this.state.rate}
                                                                    onChange={this.onChangehandler}
                                                                // validate={{ required: { value: true } }}
                                                                />
                                                            </>}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="mb-3">
                                                        {this.state.loading ?
                                                            <><Skeleton width={100} /><Skeleton height={37} /></> :
                                                            <>
                                                                <AvField type="select" name="unit_id" label="Unit" errorMessage="Choose Unit"
                                                                    // validate={{ required: { value: true } }} 
                                                                    value={this.state.unit_id} onChange={this.onChangehandler} >
                                                                    <option value="" key="" >--Select--</option>
                                                                    {this.state.units.map((unit) => (
                                                                        <option value={unit.id} key={unit.id} >{unit.unit}</option>
                                                                    ))}
                                                                </AvField>
                                                            </>}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">

                                                <div className="col-lg-12">
                                                    <div className="mb-3">
                                                        {this.state.loading ?
                                                            <><Skeleton width={100} /><Skeleton height={37} /></> :
                                                            <>
                                                                <label htmlFor="progresspill-address-input">
                                                                    Description
                                                                </label>
                                                                <AvField
                                                                    name="description"
                                                                    type="text"
                                                                    placeholder="Enter description"
                                                                    errorMessage="Enter description"
                                                                    value={this.state.description}
                                                                    onChange={this.onChangehandler}
                                                                    validate={{ required: { value: false } }}
                                                                />
                                                            </>}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="d-flex flex-wrap gap-2">
                                                {this.state.loading ?
                                                    <><Skeleton width={100} height={37} />{" "}<Skeleton width={100} height={37} /></> :
                                                    <>
                                                        {this.state.isProgress ?
                                                            <>
                                                                <button type="submit" className="btn btn-primary " disabled={true}>
                                                                    <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>Submiting
                                                                </button>
                                                            </> :
                                                            <>
                                                                <Button type="submit" color="primary" className="">
                                                                    Submit
                                                                </Button>{" "}
                                                                <Button type="reset" color="secondary" className="" onClick={this.resetForm}>
                                                                    Cancel
                                                                </Button>
                                                            </>
                                                        }
                                                    </>
                                                }
                                            </div>
                                        </AvForm>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div></div>
            </React.Fragment>
        )
    }
}
const mapStateToProps = (state:{ units: any,taxes: any }) => {   
    return{  
        units: state.units.units,      
        taxes: state.taxes.taxes
    }
}

export default connect(mapStateToProps)(withRouter(ProductCreate));
