import React, { Component } from "react";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import { AvForm, AvField, AvRadioGroup, AvRadio } from "availity-reactstrap-validation";
import {
    Row, Col, Card, CardBody, CardTitle,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    CardHeader,
    Nav,
    Table,
    BreadcrumbItem,
} from "reactstrap";

//Import Breadcrumb
import "../Datatable/datatables.scss";
import classnames from "classnames";
import InvoiceListAll from "./InvoiceListAll";
import InvoiceListUnpaid from "./InvoiceListUnpaid";
import { get } from "src/helpers/api_helper";
import InvoiceListPaid from "./InvoiceListPaid";
import MoneyIcon from '../../assets/images/icons/money';
import adbanner from "../../assets/images/ad.png";

import Select from 'react-select';
import { ActionMeta, OnChangeValue, Options } from "react-select/dist/declarations/src";

import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css';

import MetaTags from "react-meta-tags";

import ExpenseIcon from '../../assets/images/icons/Expense';


//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Estimate2Icon from "../../assets/images/icons/Estimate2";
import Addcustomer2Icon from "src/assets/images/icons/Customer2";
import Invoice2Icon from "src/assets/images/icons/Invoice2";
import RenewPlan from "../Payments/RenewPlan";
import InvoiceDetailsPdf from "./InvoiceDetailsPdf";

interface MyProps extends RouteComponentProps<any> {

}

interface MyState {
    activeTab: number;
    totalAll: number;
    totalAllPaid: number;
    totalAllUnpaid: number;
    permissions: Array<any>;
    isLoading: boolean;
    valueCustomer: Option | null | undefined;
    options: Option[];
    customers: Array<any>;
    startDate: Date | null;
    endDate: Date | null;
    loading: boolean;
    isLoadedThis: boolean;
    isLoadedTabAll: boolean;
    isLoadedTabPaid: boolean;
    isLoadedTabUnpaid: boolean;
    isPlanExpired: number;
    modalPlan: boolean;
    pdfId: string;
    isDownloadPdf: boolean;
};

interface ChangeProp {
    page: number;
    sizePerPage: number;
}

interface Option {
    label: string;
    value: string;
}

const createOption = (label: string, value: string) => ({
    label,
    value,
});
class InvoiceList extends Component<MyProps, MyState> {
    constructor(props: any) {
        super(props);
        this.state = {
            activeTab: 1,
            totalAll: 0.00,
            totalAllPaid: 0.00,
            totalAllUnpaid: 0.00,
            permissions: [],
            isLoading: true,
            valueCustomer: null,
            options: [],
            customers: [],
            startDate: null,
            endDate: null,
            loading: true,
            isLoadedThis: false,
            isLoadedTabAll: false,
            isLoadedTabPaid: false,
            isLoadedTabUnpaid: false,
            isPlanExpired: 0,
            pdfId: '',
            isDownloadPdf: false,
            modalPlan: false
        };
    }
    setActiveTab = (tab: any) => {
        this.setState({ activeTab: tab });
    }
    toggleTab = (tab: any) => {
        if (this.state.activeTab !== tab) {
            this.setActiveTab(tab);
        }
    }

    setDownloadpdf = (id: string) => {
        this.setState({ isDownloadPdf: true, pdfId: id });
        console.log('setDownloadpdf');
    }

    isDownloadCompleted = () => {
        this.setState({ isDownloadPdf: false, pdfId: '' });
        console.log('isDownloadCompleted');
    }
    handleChange = async (
        newValue: OnChangeValue<Option, false>,
        actionMeta: ActionMeta<Option>
    ) => {
        this.setState({ valueCustomer: newValue });

    };

    setTotalAmount = async (param: {}) => {
        this.setState(param);
        var loading = false;
        if (this.state.isLoadedThis && this.state.isLoadedTabAll && this.state.isLoadedTabPaid && this.state.isLoadedTabUnpaid) {
            loading: true;
        }
        this.setState({ loading });
    };

    setStartDate(dateRange: Array<any>) {
        const [startDate, endDate] = dateRange;
        this.setState({ startDate: startDate, endDate: endDate });
    }

    isPlanExpired = async (e: any, location: string) => {
        try {
            if (this.state.isPlanExpired) {
                this.props.history.push(location);
            } else {
                this.openModalPlan();
            }
        } catch (err) {
            console.error(err);
        }
    }

    openModalPlan = () => {
        this.setState({ modalPlan: true });
    }

    closeModalPlan = () => {
        this.setState({ modalPlan: false });
    }

    async componentDidMount() {
        try {

            const getAuthUser: any = localStorage.getItem("authUser");
            const obj = JSON.parse(getAuthUser);
            this.setState({ isPlanExpired: obj.isPlanExpired });

            let urls = [
                process.env.REACT_APP_API_URL + '/api/permissions/3',
                process.env.REACT_APP_API_URL + '/api/customers'
            ];

            Promise.all(urls.map((url) => get(url))).then(([respPer, respC]) => {
                this.setState({ permissions: respPer.data });
                this.setState({ customers: respC.data });

                this.state.customers.map((customer) => {

                    let newOption = createOption(customer.customer_name, customer.id);



                    this.setState({
                        options: [...this.state.options, newOption]
                    });
                }
                );

            });

            var loading = false;
            if (this.state.isLoadedThis && this.state.isLoadedTabAll && this.state.isLoadedTabPaid && this.state.isLoadedTabUnpaid) {
                loading: true;
            }

            this.setState({
                isLoading: false,
                isLoadedThis: true,
                loading
            });

        } catch (err) {
            console.error(err);
        }
    }
    render() {
        const title = "Invoices";
        const breadcrumbItem = "Invoices List";
        return (
            <React.Fragment>
                <div className="page-content" style={{ minHeight: '685px' }}>
                    <MetaTags>
                        <title>{title}</title>
                    </MetaTags>
                    <div className="container-fluid">
                        <Row>
                            <Col xs="12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between form-wrap">
                                    <div className="header"> <h4 className="mb-0 font-size-18">{breadcrumbItem}</h4>
                                        <div className="form-inline">
                                            <AvForm >
                                                <Row>
                                                    <Col lg={6}>

                                                        {this.state.loading == true ? (
                                                            <Skeleton width={'100%'} height={'50%'} />
                                                        ) : (
                                                            <Select
                                                                isClearable={true}
                                                                isDisabled={this.state.isLoading}
                                                                isLoading={this.state.isLoading}
                                                                onChange={this.handleChange}
                                                                options={this.state.options}
                                                                value={this.state.valueCustomer}
                                                                name="customer"
                                                                placeholder="Customer"
                                                            />
                                                        )}
                                                    </Col>
                                                    <Col lg={6}>

                                                        {this.state.loading == true ? (
                                                            <Skeleton width={'100%'} height={'50%'} />
                                                        ) : (
                                                            <DatePicker
                                                                selected={this.state.startDate}
                                                                onChange={(date: Array<any>) => this.setStartDate(date)}
                                                                selectsRange={true}
                                                                startDate={this.state.startDate}
                                                                endDate={this.state.endDate}
                                                                dateFormat="yyyy-MM-dd"
                                                                className="form-control calendar"
                                                                isClearable={true}
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                dropdownMode="select"
                                                                todayButton="Today"
                                                                monthsShown={2}
                                                            />
                                                        )}

                                                    </Col>
                                                </Row>
                                            </AvForm>
                                        </div>
                                    </div>

                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <BreadcrumbItem>
                                                <Link to="#">{title}</Link>
                                            </BreadcrumbItem>
                                            <BreadcrumbItem active>
                                                <Link to="#">{breadcrumbItem}</Link>
                                            </BreadcrumbItem>
                                        </ol>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-12">
                                <Card>

                                    <CardHeader>
                                        <div className="row">
                                            <div className="col-md-12 col-xl-7 order-xl-1">

                                                <ul className="values">
                                                    {this.state.loading == true ? (
                                                        <>
                                                            <li><Skeleton width={'30%'} height={'100%'} /></li>
                                                            <li><Skeleton width={'30%'} height={'100%'} /></li>
                                                            <li><Skeleton width={'30%'} height={'100%'} /></li>
                                                        </>

                                                    ) : (
                                                        <>
                                                            <li className={this.state.activeTab == 1 ? "active" : ""}>
                                                                <NavLink
                                                                    style={{ cursor: "pointer" }}
                                                                    className={classnames({
                                                                        active: this.state.activeTab == 1,
                                                                    })}
                                                                    onClick={() => {
                                                                        this.toggleTab(1);
                                                                    }}
                                                                >
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="32.815" height="49.055" viewBox="0 0 32.815 49.055">
                                                                        <path id="Path_441" data-name="Path 441" d="M32.681-43.765H24.729a13.718,13.718,0,0,1,2.729,6.671H34.23l-1.55,5.29H27.56q-.606,5.98-4.75,9T11.287-19.777L27.9-.406V0H20.249L2.392-20.882l-.034-4.185h8.389A12.034,12.034,0,0,0,17.587-26.9a7.875,7.875,0,0,0,3.335-4.9H1.415l1.55-5.29h17.89q-1.617-6.4-9.905-6.671H1.482l1.583-5.29H34.23Z" transform="translate(-1.415 49.055)" />
                                                                    </svg>


                                                                    <span>{this.state.totalAll}</span>
                                                                    <label>Total</label>
                                                                </NavLink>
                                                            </li>
                                                            <li className={this.state.activeTab == 2 ? "active" : ""}>
                                                                <NavLink
                                                                    style={{ cursor: "pointer" }}
                                                                    className={classnames({
                                                                        active: this.state.activeTab == 2,
                                                                    })}
                                                                    onClick={() => {
                                                                        this.toggleTab(2);
                                                                    }}
                                                                >
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="32.815" height="49.055" viewBox="0 0 32.815 49.055">
                                                                        <path id="Path_441" data-name="Path 441" d="M32.681-43.765H24.729a13.718,13.718,0,0,1,2.729,6.671H34.23l-1.55,5.29H27.56q-.606,5.98-4.75,9T11.287-19.777L27.9-.406V0H20.249L2.392-20.882l-.034-4.185h8.389A12.034,12.034,0,0,0,17.587-26.9a7.875,7.875,0,0,0,3.335-4.9H1.415l1.55-5.29h17.89q-1.617-6.4-9.905-6.671H1.482l1.583-5.29H34.23Z" transform="translate(-1.415 49.055)" />
                                                                    </svg>


                                                                    <span>{this.state.totalAllPaid}</span>
                                                                    <label>Paid</label>
                                                                </NavLink>
                                                            </li>
                                                            <li className={this.state.activeTab == 3 ? "active" : ""}>
                                                                <NavLink
                                                                    style={{ cursor: "pointer" }}
                                                                    className={classnames({
                                                                        active: this.state.activeTab == 3,
                                                                    })}
                                                                    onClick={() => {
                                                                        this.toggleTab(3);
                                                                    }}
                                                                >
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="32.815" height="49.055" viewBox="0 0 32.815 49.055">
                                                                        <path id="Path_441" data-name="Path 441" d="M32.681-43.765H24.729a13.718,13.718,0,0,1,2.729,6.671H34.23l-1.55,5.29H27.56q-.606,5.98-4.75,9T11.287-19.777L27.9-.406V0H20.249L2.392-20.882l-.034-4.185h8.389A12.034,12.034,0,0,0,17.587-26.9a7.875,7.875,0,0,0,3.335-4.9H1.415l1.55-5.29h17.89q-1.617-6.4-9.905-6.671H1.482l1.583-5.29H34.23Z" transform="translate(-1.415 49.055)" />
                                                                    </svg>


                                                                    <span>{this.state.totalAllUnpaid}</span>
                                                                    <label>Unpaid</label>
                                                                </NavLink>
                                                            </li>
                                                        </>
                                                    )}
                                                </ul>
                                            </div>
                                            <div className="col-md-12 col-xl-5 order-xl-1">
                                                {this.state.permissions.find(({ action, is_active }) => action == "create" && is_active == 1) &&
                                                    (
                                                        <ul className="qck-link1">
                                                            <li>
                                                                <Link to="/product_add" >
                                                                    <span><ExpenseIcon /></span>
                                                                    <label>Add Item</label>
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link to="/customer_add" >
                                                                    <span><Addcustomer2Icon /></span>
                                                                    <label>Add Customer</label>
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link to="/estimation_add"  >
                                                                    <span><Estimate2Icon /></span>
                                                                    <label>New Estimate</label>
                                                                </Link>
                                                            </li>

                                                            <li>
                                                                <Link to="/invoice_add"  >
                                                                    <span><Invoice2Icon /></span>
                                                                    <label>New Invoice</label>
                                                                </Link>
                                                            </li>
                                                        </ul>
                                                    )
                                                }
                                            </div>
                                        </div>

                                    </CardHeader>

                                    <CardBody>


                                        <TabContent
                                            activeTab={this.state.activeTab}
                                            className="text-muted"
                                        >
                                            <TabPane tabId={1}>
                                                <Row>
                                                    <Col sm="12">
                                                        <InvoiceListAll valueCustomer={this.state.valueCustomer} startDate={this.state.startDate} endDate={this.state.endDate} setTotalAmount={this.setTotalAmount} setDownloadpdf={this.setDownloadpdf} />
                                                    </Col>
                                                </Row>
                                            </TabPane>
                                            <TabPane tabId={2}>
                                                <Row>
                                                    <Col sm="12">
                                                        <InvoiceListPaid valueCustomer={this.state.valueCustomer} startDate={this.state.startDate} endDate={this.state.endDate} setTotalAmount={this.setTotalAmount} setDownloadpdf={this.setDownloadpdf} />
                                                    </Col>
                                                </Row>
                                            </TabPane>
                                            <TabPane tabId={3}>
                                                <Row>
                                                    <Col sm="12">
                                                        <InvoiceListUnpaid valueCustomer={this.state.valueCustomer} startDate={this.state.startDate} endDate={this.state.endDate} setTotalAmount={this.setTotalAmount} setDownloadpdf={this.setDownloadpdf} />
                                                    </Col>
                                                </Row>
                                            </TabPane>
                                        </TabContent>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>
                <InvoiceDetailsPdf id={this.state.pdfId} isDownload={this.state.isDownloadPdf} isDownloadCompleted={this.isDownloadCompleted} />
            </React.Fragment>
        );
    }
}

export default withRouter(InvoiceList); 