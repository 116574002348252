import React, { Component } from "react";
import MetaTags from "react-meta-tags";
import { Container, Label, Table, UncontrolledAlert } from "reactstrap";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import { Row, Col, Card, CardBody, CardTitle, CardHeader, Button, Alert, Modal } from "reactstrap";
import { AvForm, AvField, AvRadioGroup, AvRadio } from "availity-reactstrap-validation";
import Select from 'react-select';

import Breadcrumbs from "../../components/Common/Breadcrumb";
import { post, get } from "src/helpers/api_helper";
import { ActionMeta, OnChangeValue, Options } from "react-select/dist/declarations/src";

import Moment from 'moment';

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { isEmpty, isNull, round } from "lodash";
interface Params extends RouteComponentProps {

}
interface Option {
    label: string;
    value: string;
}
interface State {
    vendors: Array<any>;
    items: Array<any>;
    banks: Array<any>;
    dtls: Array<any>;
    invoice_no: string;
    invoice_date: string;
    payment_date: string;
    heads_id: { label: string, value: string };
    vendor_id: { label: string, value: string };
    desciption: string;
    amount: number;
    isProgress: boolean;
    isHideSuccessMgs: boolean;
    isHideErrorMgs: boolean;
    subTotal: number;
    tax: number;
    discount: number;
}


interface States extends State {
    id: string;

};
const createOption = (label: string, value: string) => ({
    label,
    value,
});


class PurchaseCreate extends Component<Params, States> {

    constructor(props: any) {
        super(props);
        this.state = {
            id: "0",
            vendors: [],
            items: [],
            banks: [],
            dtls: [
                { hdr_id: '', item_id: { label: '', value: '' }, qty: 0, rate: 0, discount: 0, tax: 0, amount: 0 }
            ],
            invoice_no: '',
            invoice_date: '',
            payment_date: '',
            heads_id: { label: '', value: '' },
            vendor_id: { label: '', value: '' },
            desciption: '',
            amount: 0,
            isProgress: false,
            isHideSuccessMgs: true,
            isHideErrorMgs: true,
            subTotal: 0,
            tax: 0,
            discount: 0
        };

    }
    handleValidSubmit = async (event: any, values: any) => {
        values["dtls"] = this.state.dtls;
        values["vendor_id"] = this.state.vendor_id.value;
        values["heads_id"] = this.state.heads_id.value;
        values["amount"] = this.state.amount;
        values["id"] = 0;
        // console.log(values);
        this.setState({ isProgress: true });
        const resp: any = await post(process.env.REACT_APP_API_URL + '/api/purchase_save', values);
        if (resp.success == true) {
            this.setState({
                isHideSuccessMgs: false
            });
            this.props.history.push("/expenses");
        }
        else {
            this.setState({
                isHideErrorMgs: false
            });
        }
        this.setState({ isProgress: false });
    }
    removeRow = (i: number) => {
        const dtls = this.state.dtls;
        dtls.splice(i, 1);
        this.setState({ dtls: dtls });
    }
    addRow = () => {
        let newRaw = { hdr_id: '', item_id: { label: '', value: '' }, qty: 0, rate: 0, discount: 0, tax: 0, amount: 0 }
        this.setState({ dtls: [...this.state.dtls, newRaw] });
    }
    resetForm = () => {
        this.props.history.push("/expenses");
    }
    componentDidMount() {
        const fetchData = async () => {
            try {
                let urls = [
                    process.env.REACT_APP_API_URL + `/api/bank_list`,
                    process.env.REACT_APP_API_URL + '/api/get_exp_items',
                    process.env.REACT_APP_API_URL + '/api/get_vendors'
                ];

                Promise.all(urls.map((url) => get(url))).then(([respB, resI, resV]) => {
                    let newOption = createOption('Cash', '1');
                    this.setState({ banks: [...this.state.banks, newOption] });
                    respB.data.map((bank: { bank_name: string, id: string }) => {
                        let newOption = createOption(bank.bank_name, bank.id);
                        this.setState({ banks: [...this.state.banks, newOption] });
                    }
                    );
                    resI.data.map((item: { item_name: string, id: string }) => {
                        let newOption = createOption(item.item_name, item.id);
                        this.setState({ items: [...this.state.items, newOption] });
                    }
                    )
                    resV.data.map((vendor: { vendor_name: string, id: string }) => {
                        let newOption = createOption(vendor.vendor_name, vendor.id);
                        this.setState({ vendors: [...this.state.vendors, newOption] });
                    }
                    )
                });

            } catch (error) {
                console.error(error);
            }

        }
        fetchData();
    }
    handleItemChange = async (newValue: OnChangeValue<Option, false>, actionMeta: ActionMeta<Option>, i: number, dtl: any) => {
        let dtls = this.state.dtls;
        dtl.item_id = newValue;
        dtls[i] = dtl;
        this.setState({ dtls: dtls });
    };
    handleChange = async (
        newValue: OnChangeValue<Option, false>,
        actionMeta: ActionMeta<Option>
    ) => {
        let name: any = actionMeta.name;
        let data: any = {};
        data[name] = newValue;
        this.setState(data);
    }
    onChangehandler = (e: any) => {
        let name = e.target.name;
        let value = e.target.value;
        let data: any = {};
        data[name] = value;
        this.setState(data);
    }
    onChangehandlerDtl = (e: any, i: number, dtl: any) => {
        let name = e.target.name;
        let value = e.target.value;
        let dtls = this.state.dtls;
        dtl[name] = value;
        dtls[i] = dtl;
        let totalAmount = dtls.reduce((Pre, Cur) => Pre = parseFloat(Pre) + parseFloat(Cur.amount), 0);
        let subTotal = dtls.reduce((Pre, Cur) => Pre = parseFloat(Pre) + (parseFloat(Cur.qty)*parseFloat(Cur.rate)), 0);;
        let totalTax = dtls.reduce((Pre, Cur) => Pre = parseFloat(Pre) + parseFloat(Cur.tax), 0);
        let totalDiscount = dtls.reduce((Pre, Cur) => Pre = parseFloat(Pre) + parseFloat(Cur.discount), 0);
        this.setState({ 
            subTotal: subTotal,
            tax: totalTax,
            discount: totalDiscount,
            dtls: dtls, 
            amount: totalAmount 
        });
    }
    render() {
        return (
            <React.Fragment>

                <div className="page-content">
                    <MetaTags>
                        <title>Purchase Bill</title>
                    </MetaTags>
                    <div className="container-fluid">
                        <Breadcrumbs title="Create Purchase Bill" breadcrumbItem="Create Purchase Bill" />

                        <Row>
                            <Col className="col-12">

                                <UncontrolledAlert hidden={this.state.isHideSuccessMgs} color="success">
                                    Expenses save successfully
                                </UncontrolledAlert>

                                <UncontrolledAlert hidden={this.state.isHideErrorMgs} color="danger">
                                    Somthing went wrong, Please try again
                                </UncontrolledAlert>
                                <AvForm onValidSubmit={this.handleValidSubmit}>
                                    <div className="form-head mb-3">
                                        <Row>
                                            <Col xl={6}>
                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        <label htmlFor="progresspill-address-input">
                                                            Vendor
                                                        </label>
                                                        <Select
                                                            isClearable
                                                            options={this.state.vendors}
                                                            value={this.state.vendor_id}
                                                            onChange={this.handleChange}
                                                            name="vendor_id"
                                                            validate={{ required: { value: true } }}
                                                        />
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="mb-3">

                                                            <label htmlFor="progresspill-address-input">
                                                                Invoice Number
                                                            </label>
                                                            <AvField
                                                                name="invoice_no"
                                                                type="text"
                                                                placeholder="Enter Invoice Number"
                                                                errorMessage="Enter Invoice Number"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col xl={6}>
                                                <div className="row">
                                                    <div className="col-lg-6">

                                                        <label htmlFor="progresspill-address-input">
                                                            Invoice Date
                                                        </label>

                                                        <AvField
                                                            name="invoice_date"
                                                            type="date"
                                                            placeholder="Enter Invoice Date"
                                                            errorMessage="Enter Invoice Date"
                                                            value={Moment().format('YYYY-MM-DD')}
                                                            validate={{ required: { value: true } }}
                                                        />
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className="row">
                                        <div className="table-responsive">
                                            <Table className="table mb-0 cstm-tbl">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th style={{ width: "2%" }}>#</th>
                                                        <th >Item</th>
                                                        <th style={{ width: "10%" }}>Qty</th>
                                                        <th style={{ width: "10%" }}>Rate</th>
                                                        <th style={{ width: "10%" }}>Discount</th>
                                                        <th style={{ width: "10%" }}>Tax</th>
                                                        <th style={{ width: "10%" }}>Amount</th>
                                                        <th style={{ width: "2%" }}>Remove</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.dtls.map((dtl, i) => {
                                                        return (
                                                            <tr key={i}>
                                                                <th scope="row" className="count">{(i + 1)}</th>
                                                                <td>

                                                                    <div className="mb-3">

                                                                        <Select
                                                                            isClearable
                                                                            options={this.state.items}
                                                                            value={dtl.item_id}
                                                                            onChange={(newValue: OnChangeValue<Option, false>, actionMeta: ActionMeta<Option>) => this.handleItemChange(newValue, actionMeta, i, dtl)}
                                                                            name="item_id"
                                                                        />


                                                                    </div>

                                                                </td>
                                                                <td>
                                                                    <AvField
                                                                        name="qty"
                                                                        type="number"
                                                                        placeholder="Enter qty"
                                                                        errorMessage="Enter qty"
                                                                        value={dtl.qty}
                                                                        onChange={(e: any) => this.onChangehandlerDtl(e, i, dtl)}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <AvField
                                                                        name="rate"
                                                                        type="number"
                                                                        placeholder="Enter rate"
                                                                        errorMessage="Enter rate"
                                                                        value={dtl.rate}
                                                                        onChange={(e: any) => this.onChangehandlerDtl(e, i, dtl)}
                                                                    />
                                                                </td>
                                                                <td>

                                                                    <div className="mb-3">
                                                                        <AvField
                                                                            name="discount"
                                                                            type="number"
                                                                            placeholder="Amount"
                                                                            value={dtl.discount_amt}
                                                                            onChange={(e: any) => this.onChangehandlerDtl(e, i, dtl)}
                                                                        />
                                                                    </div>

                                                                </td>
                                                                <td>
                                                                    <div className="mb-3">
                                                                        <AvField
                                                                            name="tax"
                                                                            type="number"
                                                                            placeholder="Tax amount"
                                                                            errorMessage="Tax amount"
                                                                            value={dtl.tax_amt}
                                                                            onChange={(e: any) => this.onChangehandlerDtl(e, i, dtl)}
                                                                        />
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <AvField
                                                                        name="amount"
                                                                        type="number"
                                                                        placeholder="Enter amount"
                                                                        errorMessage="Enter amount"
                                                                        value={dtl.amount}
                                                                        onChange={(e: any) => this.onChangehandlerDtl(e, i, dtl)}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <Link to="#" className="btn tbl-btn" onClick={() => this.removeRow(i)}>
                                                                        <i className="fas fa-trash"></i>
                                                                    </Link>
                                                                </td>
                                                            </tr>)
                                                    }
                                                    )}


                                                </tbody>
                                            </Table>

                                        </div>
                                        <div className="col-md-12">
                                            <div className="mb-5 add-row">
                                                <Link to="#" className="btn" onClick={() => this.addRow()}>
                                                    <i className="bx bx-plus me-1"></i> Add Row
                                                </Link>

                                            </div>
                                        </div>
                                    </div>

                                    <div className="row justify-content-between">
                                        <div className="col-lg-4">
                                            <h5 className="font-size-14 mb-4">
                                                Payment Information
                                            </h5>
                                            <Row className="mb-4">
                                                <Label
                                                    htmlFor="horizontal-firstname-input"
                                                    className="col-sm-3 col-form-label"
                                                >
                                                    Payment Date
                                                </Label>
                                                <Col >
                                                    <AvField
                                                        name="payment_date"
                                                        type="date"
                                                        placeholder="Enter Payment Date"
                                                        errorMessage="Enter Payment Date"
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className="mb-4">
                                                <Label
                                                    htmlFor="horizontal-firstname-input"
                                                    className="col-sm-3 col-form-label"
                                                >
                                                    Mode
                                                </Label>
                                                <Col >
                                                    <Select
                                                        isClearable
                                                        options={this.state.banks}
                                                        value={this.state.heads_id}
                                                        onChange={this.handleChange}
                                                        name="heads_id"
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className="mb-4">
                                                <Label
                                                    htmlFor="horizontal-firstname-input"
                                                    className="col-sm-3 col-form-label"
                                                >
                                                    Description
                                                </Label>
                                                <Col >
                                                    <AvField
                                                        name="description"
                                                        type="textarea"
                                                    />
                                                </Col>
                                            </Row>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="total">
                                                <ul>
                                                    <li>
                                                        <label>Sub Total</label>
                                                        <span>{this.state.subTotal.toFixed(2)}</span>
                                                    </li>
                                                    <li>
                                                        <label>Discount</label>
                                                        <span>{this.state.discount.toFixed(2)}</span>
                                                    </li>
                                                    <li>
                                                        <label>Tax</label>
                                                        <span>{this.state.tax.toFixed(2)}</span>
                                                    </li>
                                                    <li>
                                                        <label>Gross Total</label>
                                                        <span>{this.state.amount.toFixed(2)}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="d-flex justify-content-center gap-2 mb-4">
                                        {this.state.isProgress ?
                                            <><Skeleton width={100} height={37} />{" "}<Skeleton width={100} height={37} /></> :
                                            <>
                                                <Button type="submit" color="primary" className="" >
                                                    Submit
                                                </Button>{" "}
                                                <Button type="reset" color="secondary" className="" onClick={this.resetForm}>
                                                    Cancel
                                                </Button>
                                            </>
                                        }
                                    </div>
                                </AvForm>


                            </Col>
                        </Row>
                    </div>

                </div>
            </React.Fragment >
        )
    }
}

export default withRouter(PurchaseCreate);
