import React, { useCallback, useState } from "react";

import VendorList from "./ExpenseList";

const Expenses = () => {  

  return (
    <React.Fragment>          
      <VendorList />              
    </React.Fragment>
  );
};

export default Expenses;
