import React, { Component } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import axios from 'axios';
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";

import { Row, Col, Alert, Container } from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";

// actions
import { userForgetPassword } from "../../store/actions";

//Import config
import config from "../../config";
// import CarouselPageNew from "./CarouselPageNew";
import CarouselPageNew from "./CarouselPageNew";


import classnames from "classnames";
import { Link } from "react-router-dom";

// import images
import logo from "../../assets/images/logo-sm.svg";

import { AvForm, AvField } from "availity-reactstrap-validation";


type WizardState = {
  id: string,
  password: string;
  password_confirmation: string;
  code: string;
  is_success: number;
  is_success_password: number,
  is_success_msg: string,
  is_progress: number;
  is_error: number;
};
interface MatchParams {
  code: string;
}

interface response {
  razorpay_payment_id: string;
  razorpay_order_id: string;
  razorpay_signature: string;
  orderId: string;
}
class ForgetWizard extends Component<RouteComponentProps<MatchParams>, WizardState> {
  constructor(props: any) {
    super(props);
    const { code } = this.props.match.params;
    this.state = {

      id: "",
      password: "",
      password_confirmation: "",
      code: code,
      is_success: 0,
      is_success_password: 0,
      is_success_msg: "",
      is_progress: 0,
      is_error: 0
    };


    this.handleValidSubmit.bind(this);
  }



  onChangehandler = (e: any) => {
    let name = e.target.name;
    let value = e.target.value;
    let data: any = {};
    data[name] = value;
    this.setState(data);
  }


  handleValidSubmit = async (event: any, values: any) => {
    this.setState({
      is_progress : 1
    });
    
    const response = await axios.post(process.env.REACT_APP_API_URL + '/api/reset_password', this.state);
    if (response.data.success === true) {
      console.log(response);
      this.setState({
        is_success_password: 1,
        is_progress : 1
      });
    } else {
      this.setState({
        is_success_password: 2,
        is_success_msg: response.data.message.password,
        is_progress : 0
      });
    }

  }

  loadScript(src: string) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  componentDidMount() {

    const sendGetRequest = async () => {
      try {
        const resp = await axios.get(process.env.REACT_APP_API_URL + '/api/change_password/' + this.state.code);
        if (!resp.data.data.verified) {
          this.props.history.push("/pages-404");
        } else {
          this.setState({
            id: resp.data.data.id,
            is_success: 1,
          });
        }

      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };

    sendGetRequest();

  }

  render() {
    const appName = process.env.REACT_APP_NAME;

    return (
      <React.Fragment>
        <Col lg={4} md={5} className="col-xxl-4">
          <MetaTags>
            <title>Forget Password | {appName}</title>
          </MetaTags>
          <div className="auth-page">
            <Container fluid className="p-0">
              <Row className="g-0">

                <div className="auth-full-page-content d-flex p-sm-5 p-4">
                  <div className="w-100">
                    <div className="d-flex flex-column h-100">
                      <div className="mb-4 mb-md-5 text-center">
                        <Link to="/" className="d-block auth-logo">
                        <img className="img-fluid" src={logo} alt="" />
{" "}
                          <span className="logo-txt">{appName}</span>
                        </Link>
                      </div>
                      {this.state.is_success_password === 1 ?
                        (
                          <div className="auth-content my-auto">
                            <div className="text-center">
                              <div className="avatar-lg mx-auto">
                                <div className="avatar-title rounded-circle bg-light">
                                  <i className="bx bx-mail-send h2 mb-0 text-primary"></i>
                                </div>
                              </div>
                              <div className="p-2 mt-4">
                                <h4>Reset password successfully</h4>
                                <div className="mt-4">
                                  <Link
                                    to="/login"
                                    className="btn btn-primary w-10"
                                  >
                                    Continue to Login
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="auth-content my-auto">

                            {this.state.is_success_password === 2 ?
                              (
                                <Alert color="danger">
                                  {this.state.is_success_msg}
                                </Alert>


                              ) : null

                            }
                          </div>
                        )}
                      {this.state.is_success === 1 && this.state.is_success_password === 0 || this.state.is_success_password === 2 ?
                        (
                          <div className="auth-content my-auto">
                            <AvForm
                              className="custom-form mt-4"
                              onValidSubmit={this.handleValidSubmit}
                            >
                              <div className="mb-3">
                                <AvField
                                  name="password"
                                  label="Password"
                                  className="form-control"
                                  placeholder="Enter password"
                                  type="password"
                                  onChange={this.onChangehandler}
                                  validate={{ 
                                    required: { value: true },
                                    pattern: {value: '/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$/', errorMessage:"Password must contain one uppercase letter, one lower case, one special character,one digit and minimum 6 characters in length"},
                                    minLength: {value: 6,errorMessage:"Password must contain atleast 6 characters"}
                                  }}
                                />
                              </div>
                              <div className="mb-3">
                                <AvField
                                  name="password_confirmation"
                                  label="Confirm Password"
                                  className="form-control"
                                  placeholder="Re-Enter password"
                                  type="password"
                                  onChange={this.onChangehandler}
                                  validate={{
                                    required: { value: true },
                                    match: { value: "password" },
                                    pattern: {value: '/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$/', errorMessage:"Password must contain one uppercase letter, one lower case, one special character and one digit and minimum 6 characters in length"},
                                    minLength: {value: 6,errorMessage:"Password must contain atleast 6 characters"}
                                  }}
                                />
                              </div>
                              <div className="mb-3 mt-4">
                              { this.state.is_progress === 1 ?
                              (
                              <button
                                className="btn btn-primary w-100 waves-effect waves-light"
                                type="submit" disabled={true}
                              >
                                <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>{" "}Submiting
                              </button>
                              ) : (
                              <button
                                className="btn btn-primary w-100 waves-effect waves-light"
                                type="submit"
                              >
                                Reset
                              </button>
                              )
                              }
                              </div>
                            </AvForm>

                            <div className="mt-5 text-center">
                              <p className="text-muted mb-0">
                                Remember It ?{" "}
                                <Link
                                  to="/login"
                                  className="text-primary fw-semibold"
                                >
                                  {" "}
                                  Sign In{" "}
                                </Link>{" "}
                              </p>
                            </div>
                          </div>
                        ) : null}
                      <div className="mt-4 mt-md-5 text-center">
                        <p className="mb-0">
                          © {new Date().getFullYear()} {appName}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

              </Row>

            </Container>
          </div>
        </Col>
      </React.Fragment>
    );
  }
}

export default withRouter(ForgetWizard);

