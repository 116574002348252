import React, { useEffect,useState } from "react";
import MetaTags from "react-meta-tags";
import { Row, Col, Alert, Container } from "reactstrap";

// availity-reactstrap-validation
import { AvForm, AvField, AvRadioGroup, AvRadio } from "availity-reactstrap-validation";

// action
// import { registerUser, apiError } from "../../store/actions";

//redux
// import { useSelector, useDispatch } from "react-redux";

import { Link } from "react-router-dom";

// import images
import logo from "../../assets/images/logo-sm.svg";

import axios from 'axios';
import CarouselPageVerify from "./CarouselPageVerify";
import RegisterWizard from "./RegisterWizard";

const Verify = () => {
  // const dispatch = useDispatch();
  const [data, setData] = useState({ success : null, message : "" }); 
  const [firstName, setFirstName] = useState(""); 
  const [secondName, setSecondName] = useState("");
  const [email, setEmail] = useState("");
  const [gender, setGender] = useState("");

  const appName = process.env.REACT_APP_NAME;  

/*   const { user, registrationError } = useSelector((state: any) => ({
    user: state.register.user,
    registrationError: state.register.registrationError,
    loading: state.register.loading,
  })); */

  // handleValidSubmit
  const handleValidSubmit = (values: any) => {    
    
    const sendPostRequest = async () => {
        try {

          const resp = await axios.post(process.env.REACT_APP_API_URL + '/api/register', values);            
          setData(resp.data);          

          /* setFirstName("");
          setSecondName("");
          setEmail("");
          setGender(""); */          

        } catch (err) {
            // Handle Error Here
            console.error(err);
        }
    };

    sendPostRequest(); 
    // dispatch(registerUser(values));
  };

/*   useEffect(() => {
    dispatch(apiError(""));
  }, [dispatch]); */



  return (
    <React.Fragment>
      <MetaTags>
        <title>Verify {appName}</title>
      </MetaTags>
      <div className="auth-page">
        <Container fluid className="p-0">
          <Row className="g-0">
            <CarouselPageVerify />
            <RegisterWizard />            
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Verify;
