import React, { Component, useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { Row, Col, Card, CardBody, CardTitle, CardHeader, Button, UncontrolledAlert } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import { post, get } from "src/helpers/api_helper";
import { useHistory } from "react-router";

import Select from 'react-select';
import { ActionMeta, OnChangeValue, Options } from "react-select/dist/declarations/src";
import moment from "moment";

interface Option {
    label: string;
    value: string;
}
const createOption = (label: string, value: string) => ({
    label,
    value,
});
const ExpenseModify = (props: any) => {
    const { id } = props.match.params;
    const [data, setData] = useState({ expense_date: '', expense_no: '', amount: 0, description: '', item_id: { label: '', value: '' }, heads_id: { label: '', value: '' } });
    const [banks, setBanks] = useState<any>([]);
    const [items, setItems] = useState<any>([]);
    const [isModalProgress, setIsModalProgress] = useState(false);
    const [isHideSuccessMgs, setIsHideSuccessMgs] = useState(true);
    const [isHideErrorMgs, setIsHideErrorMgs] = useState(true);
    const [item_id,setitem_id] = useState<any>([]);
    const [heads_id,setheads_id] = useState<any>([]);
    const [expense_prefix, setexpense_prefix ] = useState('');
    const history = useHistory();
    const handleValidSubmit = async (event: any, values: any) => {
        setIsModalProgress(true);
        values["id"] = id;
        values["item_id"] = item_id;
        values["heads_id"] = heads_id;
        const resp: any = await post(process.env.REACT_APP_API_URL + '/api/expense_save', values);
        if (resp.success == true) {
            setIsHideSuccessMgs(false);
            history.push("/expenses");
        }
        else {
            setIsHideErrorMgs(false);
        }
        setIsModalProgress(false);
    }
    const resetForm = () => {
        history.push("/expenses");
    }
    const handleItemChange = async (newValue: OnChangeValue<Option, false>, actionMeta: ActionMeta<Option>) => {
        setitem_id(newValue); 
    };
    const handleBankChange = async (newValue: OnChangeValue<Option, false>, actionMeta: ActionMeta<Option>) => {
        setheads_id(newValue);
    };
    useEffect(() => {
        const fetchData = async () => {
            try {
                let urls = [
                    process.env.REACT_APP_API_URL + `/api/bank_list`,
                    process.env.REACT_APP_API_URL + '/api/get_exp_items',
                    process.env.REACT_APP_API_URL + '/api/expense_get/' + id,
                    process.env.REACT_APP_API_URL + '/api/get_expense_no'
                ];

                Promise.all(urls.map((url) => get(url))).then(([respB, resI, resD, respE]) => {                    
                    let newOption = createOption('Cash', '1');
                    setBanks([...banks, newOption]);
                    if ('1' == resD.data.heads_id) {
                        resD.data.heads_id = newOption;
                        setheads_id(newOption);
                    }
                    respB.data.map((bank: { bank_name: string, id: string }) => {
                        let newOption = createOption(bank.bank_name, bank.id);
                        setBanks([...banks, newOption]);
                        if (bank.id == resD.data.heads_id) {
                            resD.data.heads_id = newOption;
                            setheads_id(newOption);
                        }
                    }
                    );
                    resI.data.map((item: { item_name: string, id: string }) => {
                        let newOption = createOption(item.item_name, item.id);
                        setItems([...items, newOption]);
                        if (item.id == resD.data.item_id) {
                            resD.data.item_id = newOption;
                            setitem_id(newOption); 
                        }
                    }
                    )
                    setData(resD.data);
                    setexpense_prefix(respE.data.expense_prefix); 
                });

            } catch (error) {
                console.error(error);
            }

        }
        fetchData();
    }, []);


    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Expenses</title>
                </MetaTags>
                <div className="container-fluid">
                    <Breadcrumbs title="Modify Expense" breadcrumbItem="Modify Expense" />

                    <Row>
                        <Col className="col-12">
                            <Card>

                                <CardHeader></CardHeader>

                                <CardBody>
                                    <UncontrolledAlert hidden={isHideSuccessMgs} color="success">
                                        Expenses save successfully
                                    </UncontrolledAlert>

                                    <UncontrolledAlert hidden={isHideErrorMgs} color="danger">
                                        Somthing went wrong, Please try again
                                    </UncontrolledAlert>
                                    <AvForm onValidSubmit={handleValidSubmit}>
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="mb-3">
                                                    <label htmlFor="progresspill-firstname-input">
                                                        Date
                                                    </label>
                                                    <AvField
                                                        name="expense_date"
                                                        type="date"
                                                        placeholder="Enter Date of receipt"
                                                        errorMessage="Enter Date of receipt"
                                                        validate={{ required: { value: true } }}
                                                        value={moment(data.expense_date).format('YYYY-MM-DD')}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="mb-3">
                                                    <label htmlFor="progresspill-address-input">
                                                        Expense No
                                                    </label>
                                                    <AvField
                                                        name="expense_no"
                                                        type="text"
                                                        placeholder="Expense No"
                                                        value={expense_prefix+data.expense_no}
                                                        readOnly={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="mb-3">
                                                    <label htmlFor="progresspill-address-input">
                                                        Item
                                                    </label>
                                                    <Select
                                                        isClearable
                                                        options={items}
                                                        value={item_id}
                                                        name="item_id"
                                                        onChange={(newValue: OnChangeValue<Option, false>, actionMeta: ActionMeta<Option>) => handleItemChange(newValue, actionMeta)}
                                                    />
                                                </div>
                                            </div> 
                                            <div className="col-lg-6">
                                                <div className="mb-3">
                                                    <label htmlFor="progresspill-firstname-input">
                                                        Amount
                                                    </label>
                                                    <AvField
                                                        name="amount"
                                                        type="text"
                                                        placeholder="Enter Date of receipt"
                                                        errorMessage="Enter Date of receipt"
                                                        validate={{ required: { value: true } }}
                                                        value={data.amount}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="mb-3">
                                                    <label htmlFor="progresspill-firstname-input">
                                                        Mode
                                                    </label>
                                                    <Select
                                                        isClearable
                                                        options={banks}
                                                        value={heads_id}
                                                        name="heads_id"
                                                        onChange={(newValue: OnChangeValue<Option, false>, actionMeta: ActionMeta<Option>) => handleBankChange(newValue, actionMeta)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="mb-3">
                                                    <label htmlFor="progresspill-address-input">
                                                        Description
                                                    </label>
                                                    <AvField
                                                        name="description"
                                                        type="text"
                                                        placeholder="Description"
                                                        validate={{ required: { value: true } }}
                                                        value={data.description}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="d-flex flex-wrap gap-2">
                                            {isModalProgress ?
                                                (
                                                    <button type="submit" className="btn btn-primary " disabled={true}>
                                                        <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>Submiting
                                                    </button>
                                                ) : (
                                                    <>
                                                        <Button type="submit" color="primary" className="">
                                                            Submit
                                                        </Button> {" "}
                                                        <Button type="reset" color="secondary" className="" onClick={resetForm}>
                                                            Cancel
                                                        </Button>
                                                    </>
                                                )
                                            }
                                        </div>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div></div>
        </React.Fragment >
    );
};

export default ExpenseModify;