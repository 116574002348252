import React, { Component } from "react";
import MetaTags from "react-meta-tags";
import { Container, Input, Label, Modal } from "reactstrap";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import { Row, Col, Card, CardBody, CardTitle, CardHeader, Button } from "reactstrap";
import { AvForm, AvField, AvRadioGroup, AvRadio } from "availity-reactstrap-validation";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import { post, get } from "src/helpers/api_helper";
import { connect } from "react-redux";

interface Params extends RouteComponentProps {
    newCustomerName: string,
    modal_cust_is_open: boolean,
    openModal: React.KeyboardEventHandler<any> | React.MouseEventHandler<any>,
    closeModal: Function,
    parentCallback: Function,
    countries?: Array<any>;
    states?: Array<any>;
    currencies?: Array<any>;
}

type State = {
    id: string;
    customer_name: string;
    billing_address1: string;
    billing_address2: string;
    billing_town_or_city: string;
    billing_country: string;
    billing_state: string;
    billing_pincode: string;
    shipping_address1: string;
    shipping_address2: string;
    shipping_town_or_city: string;
    shipping_country: string;
    shipping_state: string;
    shipping_pincode: string;
    phone: string;
    email: string;
    gstin: string;
    pan: string;
    tan: string;
    credit_period: number;
    credit_limit: number;
    loading: boolean;
    states: Array<any>;
    billing_is_indian_state: number;
    shipping_is_indian_state: number;
    contact_person: string;
    designation: string;
    countries: Array<any>;
    currencies: Array<any>;
    currency: string;
    place_supply: number;
    isSameBillAddress: boolean;
    countries_supply: Array<any>;
    supply_country: string;
    supply_state: string;
    supply_country_validate: boolean;
    supply_state_validate: boolean;
    isProgress: number;
};

class CustomerCreateModal extends Component<Params, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            id: "0",
            customer_name: "",
            billing_address1: "",
            billing_address2: "",
            billing_town_or_city: "",
            billing_country: "99",
            billing_state: "",
            billing_pincode: "",
            shipping_address1: "",
            shipping_address2: "",
            shipping_town_or_city: "",
            shipping_country: "",
            shipping_state: "",
            shipping_pincode: "",
            phone: "",
            email: "",
            gstin: "",
            pan: "",
            tan: "",
            credit_period: 0,
            credit_limit: 0,
            states: [],
            currencies: [],
            loading: false,
            billing_is_indian_state: 1,
            shipping_is_indian_state: 1,
            contact_person: "",
            designation: "",
            countries: [],
            currency: "INR",
            place_supply: 1,
            isSameBillAddress: true,
            countries_supply: [],
            supply_country: '99',
            supply_state: '',
            supply_country_validate: false,
            supply_state_validate: true,
            isProgress: 0
        };
    }

    componentWillReceiveProps(props: Params) {
        this.setState({
            customer_name: props.newCustomerName
        })
    }

    onChangehandler = (e: any) => {
        let name = e.target.name;
        let value = e.target.value;
        let data: any = {};
        data[name] = value;
        this.setState(data);
        if (name == "supply_country") {
            this.setState({ billing_country: value, shipping_country: value });
        }
        if (name == "supply_state") {
            this.setState({ billing_state: value, shipping_state: value });
        }
        if (this.state.isSameBillAddress) {
            this.setState({
                shipping_address1: this.state.billing_address1,
                shipping_address2: this.state.billing_address2,
                shipping_town_or_city: this.state.billing_town_or_city,
                shipping_state: this.state.billing_state,
                shipping_pincode: this.state.billing_pincode,
                shipping_country: this.state.billing_country
            })
        }
    }

    onChangehandlerSupply = (e: any) => {
        let value: number = e.target.value;
        if (value == 1) {
            let countries = this.state.countries.filter(
                country => country.id == 99
            )
            this.setState({ countries_supply: countries, supply_country: '99', supply_country_validate: false, supply_state_validate: true, billing_is_indian_state: 1, billing_country: '99', billing_state: '' });
        } else {
            let countries = this.state.countries.filter(
                country => country.id != 99
            )
            this.setState({ countries_supply: countries, supply_country: '', supply_country_validate: true, supply_state_validate: false, billing_is_indian_state: 0, billing_country: '', billing_state: '' });
        }
    }

    onChangeBillingCountryhandler = (e: any) => {
        let name = e.target.name;
        let value = e.target.value;
        let data: any = {};
        data[name] = value;
        this.setState(data);
        if (value == 99) {
            this.setState({ billing_is_indian_state: 1, billing_state: "" })
        } else {
            this.setState({ billing_is_indian_state: 0, billing_state: "" })
        }
    }

    onChangeShippingCountryhandler = (e: any) => {
        let name = e.target.name;
        let value = e.target.value;
        let data: any = {};
        data[name] = value;
        this.setState(data);
        if (value == 99) {
            this.setState({ shipping_is_indian_state: 1, shipping_state: "" })
        } else {
            this.setState({ shipping_is_indian_state: 0, shipping_state: "" })
        }
    }

    handleValidSubmit = async (event: any, values: any) => {
        this.setState({ isProgress: 1 });
        values["id"] = this.state.id;
        values["billing_is_indian_state"] = this.state.billing_is_indian_state;
        values["shipping_is_indian_state"] = this.state.shipping_is_indian_state;

        if (this.state.isSameBillAddress) {
            values["shipping_address1"] = this.state.billing_address1;
            values["shipping_address2"] = this.state.billing_address2;
            values["shipping_pincode"] = this.state.billing_pincode;
            values["shipping_country"] = this.state.billing_country;
            values["shipping_state"] = this.state.billing_state;
            values["shipping_town_or_city"] = this.state.billing_town_or_city;
        }

        const resp: any = await post(process.env.REACT_APP_API_URL + '/api/customer_save', values);
        if (resp.success === true) {
            let customer = { customer_name: resp.customer_name, id: resp.id };
            this.props.parentCallback(customer);
        } else {

        }
        this.setState({ isProgress: 0 });
    }
    handleInvalidSubmit = (event: any, errors: any, values: any) => {
        // console.log('errors');
        // console.log(errors);
    }

    copyBillAddress = async (e: any) => {
        if (e.target.checked) {
            this.setState({
                shipping_address1: this.state.billing_address1,
                shipping_address2: this.state.billing_address2,
                shipping_town_or_city: this.state.billing_town_or_city,
                shipping_state: this.state.billing_state,
                shipping_pincode: this.state.billing_pincode,
                shipping_country: this.state.billing_country,
                isSameBillAddress: true
            })
        } else {
            this.setState({
                shipping_address1: "",
                shipping_address2: "",
                shipping_town_or_city: "",
                shipping_state: "",
                shipping_pincode: "",
                shipping_country: "",
                isSameBillAddress: false
            })
        }
    }

    async componentDidMount() {        

        let countries: any = this.props.countries;
        countries = countries.filter(
            (country: { id: number }) => country.id == 99
        );
        
        let states: any = this.props.states;
        let countriesData: any = this.props.countries;
        let currencies: any = this.props.currencies;

        this.setState({
            states: states, countries: countriesData, countries_supply: countries
            , loading: false, customer_name: this.props.newCustomerName, currencies: currencies
        });
    }

    render() {
        return (
            <React.Fragment>
                <Modal
                    isOpen={this.props.modal_cust_is_open}
                    toggle={this.props.openModal}
                    className="md-700"
                >

                    <AvForm onValidSubmit={this.handleValidSubmit} onInvalidSubmit={this.handleInvalidSubmit} >
                        <div className="modal-header">
                            <h5 className="modal-title mt-0" id="myModalLabel">
                                Create Customer
                            </h5>
                            <button
                                type="button"
                                onClick={() => this.props.closeModal()}
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                id="closeButton"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <div className="modal-body">

                            <div className="grey-bg-frm">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="mb-3">
                                            <label htmlFor="progresspill-customer_name-input">
                                                Customer name
                                            </label>
                                            <AvField
                                                name="customer_name" type="text"
                                                placeholder="Enter Customer name"
                                                errorMessage="Enter Customer name"
                                                value={this.state.customer_name}
                                                validate={{ required: { value: true } }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <Row>
                                    <Col>

                                        <div className="d-flex mb-2 radio-btns justify-content-between">
                                            <label htmlFor="progresspill-place_supply-input">
                                                Place of supply
                                            </label>
                                            <AvRadioGroup inline name="place_supply" required value={this.state.place_supply} onChange={this.onChangehandlerSupply}>
                                                <AvRadio label="Domestic" value={1} />
                                                <AvRadio label="Export" value={2} />
                                            </AvRadioGroup>
                                        </div>

                                    </Col>
                                </Row>
                                <div className="row">

                                    {this.state.supply_country_validate && (
                                        <div className="col-lg-12">
                                            <div className="mb-3">
                                                <label htmlFor="progresspill-address-input">
                                                    Supply Country
                                                </label>
                                                <AvField type="select" name="supply_country" value={this.state.supply_country} validate={{ required: { value: this.state.supply_country_validate } }} onChange={this.onChangehandler} onKeyUp={this.onChangehandler}>
                                                    <option key={-1} value={""}>--Select--</option>
                                                    {this.state.countries_supply.map((country) => (
                                                        <option value={country.id} key={country.id}>{country.nicename}</option>
                                                    ))}
                                                </AvField>
                                            </div>
                                        </div>
                                    )}

                                    {this.state.supply_state_validate && (
                                        <div className="col-lg-12">
                                            <div className="mb-3">
                                                <label htmlFor="progresspill-address-input">
                                                    Supply State
                                                </label>

                                                <AvField type="select" name="supply_state" value={this.state.supply_state} validate={{ required: { value: this.state.supply_state_validate } }} onChange={this.onChangehandler} onKeyUp={this.onChangehandler}>
                                                    <option key={-1} value={""}>--Select--</option>
                                                    {this.state.states.map((state) => (
                                                        <option value={state.id} key={state.id}>{state.state}</option>
                                                    ))}
                                                </AvField>
                                            </div>
                                        </div>
                                    )}


                                    <div className="col-lg-12">
                                        <div className="mb-3">
                                            <label htmlFor="progresspill-firstname-input">
                                                Contact Person
                                            </label>
                                            <AvField
                                                name="contact_person" type="text"
                                                placeholder="Enter contact person"
                                                // errorMessage="Enter contact person"
                                                value={this.state.contact_person}
                                            // validate={{ required: { value: true } }}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-12">
                                        <div className="mb-3">
                                            <label htmlFor="progresspill-firstname-input">
                                                Currency
                                            </label>
                                            <AvField type="select" name="currency" value={this.state.currency}  >
                                                <option key={-1} value={""}>--Select--</option>
                                                {this.state.currencies.map((currency) => (
                                                    <option value={currency.code} key={currency.id}>{currency.name}({currency.code})</option>
                                                ))}
                                            </AvField>
                                        </div>
                                    </div>

                                </div>
                                <div className="row">
                                    <h5 className="mb-3">Billing Address</h5>
                                    <div className="col-lg-6">
                                        <div className="mb-3">
                                            <label htmlFor="progresspill-address-input">
                                                Flat, House no., Building, Apartment
                                            </label>
                                            <AvField
                                                name="billing_address1"
                                                type="text"
                                                placeholder="Enter Address"
                                                // errorMessage="Enter Address"
                                                value={this.state.billing_address1}
                                                // validate={{ required: { value: true } }}
                                                onChange={this.onChangehandler}
                                                onKeyUp={this.onChangehandler}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-6">
                                        <div className="mb-3">
                                            <label htmlFor="progresspill-address-input">
                                                Area, Street, Sector, Village
                                            </label>
                                            <AvField
                                                name="billing_address2"
                                                type="text"
                                                placeholder="Enter Address"
                                                // errorMessage="Enter Address"
                                                value={this.state.billing_address2}
                                                // validate={{ required: { value: true } }}
                                                onChange={this.onChangehandler}
                                                onKeyUp={this.onChangehandler}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-6">
                                        <div className="mb-3">
                                            <label htmlFor="progresspill-address-input">
                                                Town/City
                                            </label>
                                            <AvField
                                                name="billing_town_or_city"
                                                type="text"
                                                placeholder="Enter Town Or City"
                                                // errorMessage="Enter Town Or City"
                                                value={this.state.billing_town_or_city}
                                                // validate={{ required: { value: true } }}
                                                onChange={this.onChangehandler}
                                                onKeyUp={this.onChangehandler}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-6">
                                        <div className="mb-3">
                                            <label htmlFor="progresspill-address-input">
                                                Country
                                            </label>
                                            <AvField type="select" name="billing_country" value={this.state.billing_country} validate={{ required: { value: true } }} onChange={this.onChangeBillingCountryhandler} >
                                                <option key={-1} value={""}>--Select--</option>
                                                {this.state.countries.map((country) => (
                                                    <option value={country.id} key={country.id}>{country.nicename}</option>
                                                ))}
                                            </AvField>
                                        </div>
                                    </div>

                                    <div className="col-lg-6">
                                        <div className="mb-3">
                                            <label htmlFor="progresspill-address-input">
                                                State / Province
                                            </label>
                                            {this.state.billing_is_indian_state ?
                                                (
                                                    <AvField type="select" name="billing_state" value={this.state.billing_state} validate={{ required: { value: true } }} onChange={this.onChangehandler} onKeyUp={this.onChangehandler} >
                                                        <option key={-1} value={""}>--Select--</option>
                                                        {this.state.states.map((state) => (
                                                            <option value={state.id} key={state.id}>{state.state}</option>
                                                        ))}
                                                    </AvField>
                                                )
                                                :
                                                (
                                                    <AvField
                                                        name="billing_state"
                                                        type="text"
                                                        placeholder="Enter state or province"
                                                        // errorMessage="Enter state or province"
                                                        value={this.state.billing_state}
                                                        // validate={{ required: { value: true } }}
                                                        onChange={this.onChangehandler}
                                                        onKeyUp={this.onChangehandler}
                                                    />
                                                )
                                            }
                                        </div>
                                    </div>

                                    <div className="col-lg-6">
                                        <div className="mb-3">
                                            <label htmlFor="progresspill-address-input">
                                                Pincode
                                            </label>
                                            <AvField
                                                name="billing_pincode"
                                                type="text"
                                                placeholder="Enter Pincode"
                                                // errorMessage="Enter Pincode"
                                                value={this.state.billing_pincode}
                                                // validate={{ required: { value: true } }}
                                                onChange={this.onChangehandler}
                                                onKeyUp={this.onChangehandler}
                                            />
                                        </div>
                                    </div>

                                </div>

                                <div className="row">
                                    <div className="heading d-flex mt-2 mb-3 justify-content-between">
                                        <h5>Shiping Address</h5>
                                        <div className="form-check form-check-inline">
                                            {" "}<input className="form-check-input" type="checkbox" id="formCheck1" onChange={this.copyBillAddress} checked={this.state.isSameBillAddress} />
                                            <label className="form-check-label" htmlFor="formCheck1">
                                                {" (Same as billing address) "}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ display: this.state.isSameBillAddress ? "none" : "block" }}>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="mb-3">
                                                <label htmlFor="progresspill-address-input">
                                                    Flat, House no., Building, Apartment
                                                </label>
                                                <AvField
                                                    name="shipping_address1"
                                                    type="text"
                                                    placeholder="Enter Address"
                                                    // errorMessage="Enter Address"
                                                    value={this.state.shipping_address1}
                                                // validate={{ required: { value: true } }}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="mb-3">
                                                <label htmlFor="progresspill-address-input">
                                                    Area, Street, Sector, Village
                                                </label>
                                                <AvField
                                                    name="shipping_address2"
                                                    type="text"
                                                    placeholder="Enter Address"
                                                    // errorMessage="Enter Address"
                                                    value={this.state.shipping_address2}
                                                // validate={{ required: { value: true } }}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="mb-3">
                                                <label htmlFor="progresspill-address-input">
                                                    Town/City
                                                </label>
                                                <AvField
                                                    name="shipping_town_or_city"
                                                    type="text"
                                                    placeholder="Enter Town Or City"
                                                    // errorMessage="Enter Town Or City"
                                                    value={this.state.shipping_town_or_city}
                                                // validate={{ required: { value: true } }}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="mb-3">
                                                <label htmlFor="progresspill-address-input">
                                                    Country
                                                </label>
                                                <AvField type="select" name="shipping_country" value={this.state.shipping_country} validate={{ required: { value: !this.state.isSameBillAddress } }} onChange={this.onChangeShippingCountryhandler} >
                                                    <option key={-1} value={""}>--Select--</option>
                                                    {this.state.countries.map((country) => (
                                                        <option value={country.id} key={country.id}>{country.nicename}</option>
                                                    ))}
                                                </AvField>
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="mb-3">
                                                <label htmlFor="progresspill-address-input">
                                                    State / Province
                                                </label>
                                                {this.state.shipping_is_indian_state ?
                                                    (
                                                        <AvField type="select" name="shipping_state" value={this.state.shipping_state} validate={{ required: { value: !this.state.isSameBillAddress } }} >
                                                            <option key={-1} value={""}>--Select--</option>
                                                            {this.state.states.map((state) => (
                                                                <option value={state.id} key={state.id}>{state.state}</option>
                                                            ))}
                                                        </AvField>
                                                    )
                                                    :
                                                    (
                                                        <AvField
                                                            name="shipping_state"
                                                            type="text"
                                                            placeholder="Enter state or province"
                                                            // errorMessage="Enter state or province"
                                                            value={this.state.shipping_state}
                                                        // validate={{ required: { value: true } }}
                                                        />
                                                    )
                                                }
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="mb-3">
                                                <label htmlFor="progresspill-address-input">
                                                    Pincode
                                                </label>
                                                <AvField
                                                    name="shipping_pincode"
                                                    type="text"
                                                    placeholder="Enter Pincode"
                                                    // errorMessage="Enter Pincode"
                                                    value={this.state.shipping_pincode}
                                                // validate={{ required: { value: true } }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="mb-3">
                                            <label htmlFor="progresspill-firstname-input">
                                                Phone
                                            </label>
                                            <AvField
                                                name="phone" type="text"
                                                placeholder="Enter phone"
                                                // errorMessage="Enter phone"
                                                value={this.state.phone}
                                            // validate={{ required: { value: true } }}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-6">
                                        <div className="mb-3">
                                            <label htmlFor="progresspill-firstname-input">
                                                Email
                                            </label>
                                            <AvField
                                                name="email" type="email"
                                                placeholder="Enter email"
                                                // errorMessage="Enter email"
                                                value={this.state.email}
                                            // validate={{ required: { value: true } }}
                                            />
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                        <div className="modal-footer justify-content-center">
                            {this.state.isProgress ?
                                <>
                                    <button type="submit" className="btn btn-primary " disabled={true}>
                                        <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>Submiting
                                    </button>
                                </> :
                                <>
                                    <button
                                        type="button"
                                        onClick={() => this.props.closeModal()}
                                        className="btn btn-secondary "
                                        data-dismiss="modal"
                                    >
                                        Close
                                    </button>{" "}
                                    <Button type="submit" color="primary" className="">
                                        Submit
                                    </Button>
                                </>
                            }
                        </div>
                    </AvForm>
                </Modal>
            </React.Fragment>
        )
    }
}
const mapStateToProps = (state: { countries: any, states: any, currencies: any }) => {
    return {
        states: state.states.states,
        countries: state.countries.countries,
        currencies: state.currencies.currencies
    }
}
export default connect(mapStateToProps)(withRouter(CustomerCreateModal)); 
