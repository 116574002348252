import React, { Component } from "react";
import MetaTags from "react-meta-tags";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import { Col, Label, Modal, Row, Table, UncontrolledAlert } from 'reactstrap';
import { AvForm, AvField, AvRadioGroup, AvRadio } from "availity-reactstrap-validation";

import { post, get, del } from "src/helpers/api_helper";
import moment from "moment";
import { backgroundClip } from "html2canvas/dist/types/css/property-descriptors/background-clip";
import Moment from 'moment';

interface Params extends RouteComponentProps {
    modal_is_open: boolean;
    openModal: React.KeyboardEventHandler<any> | React.MouseEventHandler<any> | undefined;
    closeModal: any;
    invoice_id: string;
    customer_name: string;
    invoice_no: string;
    invoice_amount: number;
}
interface Bank {
    id: string;
    bank_name: string;
}
type State = {
    id: string;
    banks: Array<any>;
    payments: Array<any>;
    date_receipt: string;
    payment_type: string;
    received_amount: number;
    tds: number;
    discount: number;
    exchange_loss_or_gain: number;
    balance_due: number;
    notes: string;
    success: number;
    total_amount_due: number;
    total_amount_received: number;
    isModalProgress: boolean;
    isHideSuccessMgs: boolean;
    isHideErrorMgs: boolean;
};

class RecordPayment extends Component<Params, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            id: "",
            banks: [],
            payments: [],
            date_receipt: Moment().format('YYYY-MM-DD'),
            payment_type: "",
            received_amount: 0,
            tds: 0,
            discount: 0,
            exchange_loss_or_gain: 0,
            balance_due: 0,
            notes: '',
            success: 0,
            total_amount_due: 0,
            total_amount_received: 0,
            isModalProgress: false,
            isHideSuccessMgs: true,
            isHideErrorMgs: true,
        };
    }

    onChangehandler = (e: any, isNum: number = 0) => {
        let name = e.target.name;
        let value = isNum ? parseFloat(e.target.value) : e.target.value;
        let data: any = {};
        data[name] = value;
        this.setState(data);
        // this.calcTotal();
    }

    calcTotal = async () => {
        let total_amount_due: number = this.state.total_amount_due;
        let deduction: number = (this.state.received_amount + this.state.tds + this.state.discount + this.state.exchange_loss_or_gain);
        var balance_due: number = 0;
        if (this.state.id == "") {
            balance_due = (total_amount_due - deduction);
        } else {
            balance_due = (total_amount_due + this.state.total_amount_received - deduction);
        }
        this.setState({ balance_due: balance_due });
    }

    handleValidSubmit = async (event: any, values: any) => {
        this.setState({ isModalProgress: true });
        values["invoice_id"] = this.props.invoice_id;
        values["id"] = this.state.id;

        const resp: any = await post(process.env.REACT_APP_API_URL + '/api/payment_save', values);
        if (resp.success == true) {
            this.setState({
                success: 1,
                date_receipt: '',
                payment_type: '',
                received_amount: 0,
                tds: 0,
                discount: 0,
                exchange_loss_or_gain: 0,
                balance_due: 0,
                notes: '',
                id: "",
                isHideSuccessMgs: false
            });
            this.fetchList(this.props.invoice_id);
            window.location.reload();
        }
        else {
            this.setState({
                success: 0,
                isHideErrorMgs: false
            });

        }
        this.setState({ isModalProgress: false });
    }

    async componentDidMount() {
        const respPaymentMethods: any = await get(process.env.REACT_APP_API_URL + `/api/bank_list`);
        this.setState({ banks: respPaymentMethods.data });
    }

    componentDidUpdate(prevProps: Params) {
        if (this.props.invoice_id !== prevProps.invoice_id) {
            this.fetchList(this.props.invoice_id);
        }
    }

    fetchList = async (invoice_id: any) => {
        const respPaymentLst: any = await get(process.env.REACT_APP_API_URL + `/api/payment_list/${invoice_id}`);
        let payments: Array<any> = respPaymentLst.data;
        const total_amount_received: number = await payments.reduce((Pre, Cur) => Pre = parseFloat(Pre) + parseFloat(Cur.amount), 0);
        const total_amount_due: number = (this.props.invoice_amount - total_amount_received);
        this.setState({ payments: payments, total_amount_received: total_amount_received, total_amount_due: total_amount_due });
    }
    delete = async (id: any) => {
        try {
            const conf = window.confirm("Do you want to delete this record?");
            if (conf) {
                const resp: any = await del(process.env.REACT_APP_API_URL + '/api/payment_delete/' + id);
                if (resp.success) {
                    this.fetchList(this.props.invoice_id);
                    window.location.reload();
                }
            }
        } catch (err) {
            // Handle Error Here
            console.error(err);
        }
    }
    edit = async (id: any) => {
        try {
            const resp: any = await get(process.env.REACT_APP_API_URL + '/api/payment_get/' + id);

            if (resp.success) {
                this.setState(resp.data);
                this.calcTotal();
            }
        } catch (err) {
            // Handle Error Here
            console.error(err);
        }
    }
    render() {
        return (
            <Modal
                isOpen={this.props.modal_is_open}
                toggle={this.props.openModal}
                className={'md-700'}
            >
                <AvForm onValidSubmit={this.handleValidSubmit} >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myModalLabel">
                            {this.props.customer_name}
                        </h5>
                        <button
                            type="button"
                            onClick={() => this.props.closeModal()}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <UncontrolledAlert hidden={this.state.isHideSuccessMgs} color="success">
                            Payment record save successfully
                        </UncontrolledAlert>

                        <UncontrolledAlert hidden={this.state.isHideErrorMgs} color="danger">
                            Somthing went wrong, Please try again
                        </UncontrolledAlert>
                        <div className='grn-bg-frm mb-3'>
                            <Row>
                                <Col>
                                    <div className='formlabel'>
                                        <label>Invoice No</label>
                                        <span>{this.props.invoice_no}</span>

                                    </div>
                                </Col>
                                <Col>
                                    <div className='formlabel'>
                                        <label>Invoice Value</label>
                                        <span>₹ {this.props.invoice_amount}</span>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className='formlabel'>
                                        <label>Amount Received</label>
                                        <span>₹ {this.state.total_amount_received.toFixed(2)}</span>
                                    </div>
                                </Col>
                                <Col>
                                    <div className='formlabel'>
                                        <label>Amount Due</label>
                                        <span>₹ {this.state.total_amount_due.toFixed(2)}</span>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className='grey-bg-frm'>
                            <Row>
                                <Col lg={6}>
                                    <Label
                                        htmlFor="horizontal-date_receipt-input"
                                    >
                                        Date of receipt
                                    </Label>
                                    <AvField
                                        name="date_receipt"
                                        type="date"
                                        placeholder="Enter Date of receipt"
                                        errorMessage="Enter Date of receipt"
                                        validate={{ required: { value: true } }}
                                        value={Moment(this.state.date_receipt).format('YYYY-MM-DD')}
                                        onChange={(e: any) => this.onChangehandler(e)}
                                    />
                                </Col>
                                <Col lg={6}>
                                    <Label
                                        htmlFor="horizontal-payment_type-input"
                                    >
                                        Mode of Payment
                                    </Label>
                                    <AvField type="select" name="payment_type" errorMessage="Choose Mode of Payment"
                                        validate={{ required: { value: true } }}
                                        value={this.state.payment_type}
                                        onChange={(e: any) => this.onChangehandler(e)}
                                    >
                                        <option value="" key="" >--Select--</option>
                                        <option value="1" key="" >Cash</option>
                                        {this.state.banks.map((bank: Bank) => {
                                            return (
                                                <option value={bank.id} key={bank.id}>{bank.bank_name}</option>
                                            )
                                        })}
                                    </AvField>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6}>
                                    <Label
                                        htmlFor="horizontal-received_amount-input"
                                    >
                                        Received Amount
                                    </Label>
                                    <AvField
                                        name="received_amount"
                                        type="number"
                                        placeholder="Enter Received Amount"
                                        errorMessage="Enter Received Amount"
                                        validate={{ required: { value: true } }}
                                        value={this.state.received_amount}
                                        onChange={(e: any) => this.onChangehandler(e, 1)}
                                        onKeyUp={() => this.calcTotal()}
                                        readOnly={(this.state.total_amount_due > 0) ? false : true}
                                        style={(this.state.total_amount_due > 0) ? {} : { backgroundColor: "Red" }}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6}>
                                    <Label
                                        htmlFor="horizontal-tds-input"
                                    >
                                        TDS
                                    </Label>
                                    <AvField
                                        name="tds"
                                        type="number"
                                        value={this.state.tds}
                                        onChange={(e: any) => this.onChangehandler(e, 1)}
                                        onKeyUp={() => this.calcTotal()}
                                        readOnly={(this.state.total_amount_due > 0) ? false : true}
                                    />
                                </Col>
                                <Col lg={6}>
                                    <Label
                                        htmlFor="horizontal-discount-input"
                                    >
                                        Discount
                                    </Label>
                                    <AvField
                                        name="discount"
                                        type="number"
                                        value={this.state.discount}
                                        onChange={(e: any) => this.onChangehandler(e, 1)}
                                        onKeyUp={() => this.calcTotal()}
                                        readOnly={(this.state.total_amount_due > 0) ? false : true}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6}>
                                    <Label
                                        htmlFor="horizontal-exchange-input"
                                    >
                                        Exchange Gain/Loss
                                    </Label>
                                    <AvField
                                        name="exchange_loss_or_gain"
                                        type="number"
                                        value={this.state.exchange_loss_or_gain}
                                        onChange={(e: any) => this.onChangehandler(e, 1)}
                                        onKeyUp={() => this.calcTotal()}
                                        readOnly={(this.state.total_amount_due > 0) ? false : true}
                                    />
                                </Col>
                                <Col lg={6}>
                                    <Label
                                        htmlFor="horizontal-balance_due-input"
                                    >
                                        Balance Due
                                    </Label>
                                    <AvField
                                        name="balance_due"
                                        type="number"
                                        readOnly={true}
                                        value={this.state.balance_due.toFixed(2)}
                                    />
                                </Col>
                            </Row>

                            <Row className="mb-4">

                                <Col sm={12}>
                                    <Label
                                        htmlFor="horizontal-notes-input"
                                    >
                                        Notes
                                    </Label>
                                    <AvField
                                        name="notes"
                                        type="textarea"
                                        value={this.state.notes}
                                        onChange={(e: any) => this.onChangehandler(e)}
                                    />
                                </Col>
                            </Row>
                        </div>
                        <h5 className="font-size-14 mb-2">
                            Payment Received
                        </h5>
                        <div className="table-responsive">
                            <Table className="align-middle mb-0">
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Payment Type</th>
                                        <th>Amount</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.payments.map((payment: { id: string, payment_date: string, head: string, amount: number, bank_name: string, heads_id: number }) => {
                                        return (
                                            <tr key={payment.id}>
                                                <td>{moment(payment.payment_date).format("YYYY-MM-DD")}</td>
                                                <td>{payment.bank_name ? payment.bank_name : payment.head}</td>
                                                <td>{payment.amount}</td>
                                                <td className='text-nowrap'>
                                                    <div className='table-icons'>
                                                        {(payment.heads_id == 1 || payment.heads_id == 2) && (
                                                            <>
                                                                <i className="fas fa-edit" data-toggle="tooltip" data-placement="bottom" title="Edit" onClick={() => { this.edit(payment.id) }} />  {" "}
                                                            </>
                                                        )}

                                                        <i className="fas fa-trash-alt" data-toggle="tooltip" data-placement="bottom" title="Delete" onClick={() => { this.delete(payment.id) }} />
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })}

                                </tbody>
                            </Table>
                        </div>
                    </div>
                    <div className="modal-footer justify-content-center">
                        {this.state.isModalProgress ?
                            (
                                <button type="submit" className="btn btn-primary " disabled={true}>
                                    <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>Submiting
                                </button>
                            ) : (
                                <button type="submit" className="btn btn-primary ">
                                    Update Payment
                                </button>
                            )
                        }
                    </div>
                </AvForm>
            </Modal>
        )
    }
}

export default withRouter(RecordPayment);
