import React from 'react';

const AddcustomerIcon = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
  <g id="Group_592" data-name="Group 592" transform="translate(-1250 -463)">
    <rect id="Rectangle_109" data-name="Rectangle 109" width="50" height="50" transform="translate(1250 463)" fill="#ffb4b4" opacity="0"/>
    <g id="Layer_2" data-name="Layer 2" transform="translate(1252.002 463)">
      <g id="Avatar">
        <path id="Path_442" data-name="Path 442" d="M46.82,42.12l-1.28-6.07a12,12,0,0,0-5.45-7.77L32.4,23.52a11.08,11.08,0,0,0,2.15-5.46l.61-5.75A11.13,11.13,0,0,0,24.1,0H22.87A11.13,11.13,0,0,0,11.8,12.31l.62,5.75a11.08,11.08,0,0,0,2.15,5.46l-7.7,4.76a12,12,0,0,0-5.45,7.77L.14,42.12A6.53,6.53,0,0,0,6.53,50h33.9a6.53,6.53,0,0,0,6.39-7.88ZM18.31,7a6.07,6.07,0,0,1,4.56-2H24.1a6.12,6.12,0,0,1,6.09,6.78l-.61,5.74a6.13,6.13,0,0,1-12.19,0l-.61-5.74A6,6,0,0,1,18.31,7ZM41.62,44.4a1.48,1.48,0,0,1-1.19.57H6.53a1.48,1.48,0,0,1-1.19-.57A1.52,1.52,0,0,1,5,43.15l1.28-6.07A7.09,7.09,0,0,1,9.5,32.54l9.13-5.66a11.06,11.06,0,0,0,9.7,0l9.13,5.66a7.09,7.09,0,0,1,3.19,4.54l1.28,6.07a1.52,1.52,0,0,1-.31,1.28Z" fill="#111"/>
        <rect id="Rectangle_88" data-name="Rectangle 88" width="10" height="5" rx="2.5" transform="translate(25.48 36)" fill="#00c569"/>
      </g>
    </g>
  </g>
</svg>

  );
}

export default AddcustomerIcon;
