import React, { Component } from "react";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import { AvForm, AvField, AvRadioGroup, AvRadio } from "availity-reactstrap-validation";
import {
    Row, Col, Card, CardBody, CardTitle,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    CardHeader,
    Nav,
    Table,
} from "reactstrap";

//Import Breadcrumb
import "../Datatable/datatables.scss";
import classnames from "classnames";
import { get } from "src/helpers/api_helper";

//Import images
import AddcustomerIcon from '../../assets/images/icons/Customer';
import EstimateIcon from '../../assets/images/icons/Estimate';
import InvoiceIcon from '../../assets/images/icons/Invoice';
import AdditemIcon from '../../assets/images/icons/Estimate';

import adbanner from "../../assets/images/ad.png";
import { display } from "html2canvas/dist/types/css/property-descriptors/display";

import Select from 'react-select';
import { ActionMeta, OnChangeValue, Options } from "react-select/dist/declarations/src";

import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import InvoiceListAll from "../Invoices/InvoiceListAll";
import InvoiceListPaid from "../Invoices/InvoiceListPaid";
import InvoiceListUnpaid from "../Invoices/InvoiceListUnpaid";
import SplineArea from "./SplineArea";

interface MyProps extends RouteComponentProps<any> {

}

interface MyState {
    activeTab: number;
    totalAll: number;
    totalAllPaid: number;
    totalAllUnpaid: number;
    permissions: Array<any>;
    isLoading: boolean;
};

interface ChangeProp {
    page: number;
    sizePerPage: number;
}

class DashboardContent extends Component<MyProps, MyState> {
    constructor(props: any) {
        super(props);
        this.state = {
            activeTab: 1,
            totalAll: 0.00,
            totalAllPaid: 0.00,
            totalAllUnpaid: 0.00,
            permissions: [],
            isLoading: true,
        };
    }
    setActiveTab = (tab: any) => {
        this.setState({ activeTab: tab });
    }
    toggleTab = (tab: any) => {
        if (this.state.activeTab !== tab) {
            this.setActiveTab(tab);
        }
    }

    setTotalAmount = async (param: {}) => {
        this.setState(param);
    };

    setDownloadpdf = (id: string) => {
        
    }

    async componentDidMount() {
        try {
            const resp1: any = await get(process.env.REACT_APP_API_URL + '/api/get_invoice_summary');
            this.setState(resp1.data);

        } catch (err) {
            console.error(err);
        }
    }

    render() {

        return (
            <React.Fragment>
                <Row>
                    <Col className="col-12 mb-3">

                        <div className="row">
                            <div className="col-md-12 col-xl-5 text-xl-end order-xl-2">
                                <a href="#!">
                                    <img className="img-fluid" src={adbanner} alt="" />
                                </a>
                            </div>
                            <div className="col-md-12 col-xl-7 order-xl-1">
                                <ul className="values">
                                    <li className={this.state.activeTab == 1 ? "active" : ""}>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: this.state.activeTab == 1,
                                            })}
                                            onClick={() => {
                                                this.toggleTab(1);
                                            }}
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="32.815" height="49.055" viewBox="0 0 32.815 49.055">
                                                <path id="Path_441" data-name="Path 441" d="M32.681-43.765H24.729a13.718,13.718,0,0,1,2.729,6.671H34.23l-1.55,5.29H27.56q-.606,5.98-4.75,9T11.287-19.777L27.9-.406V0H20.249L2.392-20.882l-.034-4.185h8.389A12.034,12.034,0,0,0,17.587-26.9a7.875,7.875,0,0,0,3.335-4.9H1.415l1.55-5.29h17.89q-1.617-6.4-9.905-6.671H1.482l1.583-5.29H34.23Z" transform="translate(-1.415 49.055)" />
                                            </svg>
                                            <span>{this.state.totalAll}</span>
                                            <label>Total</label>
                                           
                                        </NavLink>
                                    </li>
                                    <li className={this.state.activeTab == 2 ? "active" : ""}>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: this.state.activeTab == 2,
                                            })}
                                            onClick={() => {
                                                this.toggleTab(2);
                                            }}
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="32.815" height="49.055" viewBox="0 0 32.815 49.055">
                                                <path id="Path_441" data-name="Path 441" d="M32.681-43.765H24.729a13.718,13.718,0,0,1,2.729,6.671H34.23l-1.55,5.29H27.56q-.606,5.98-4.75,9T11.287-19.777L27.9-.406V0H20.249L2.392-20.882l-.034-4.185h8.389A12.034,12.034,0,0,0,17.587-26.9a7.875,7.875,0,0,0,3.335-4.9H1.415l1.55-5.29h17.89q-1.617-6.4-9.905-6.671H1.482l1.583-5.29H34.23Z" transform="translate(-1.415 49.055)" />
                                            </svg>
                                            <span>{this.state.totalAllPaid}</span>
                                            <label>Paid</label>
                                        </NavLink>
                                    </li>
                                    <li className={this.state.activeTab == 3 ? "active" : ""}>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: this.state.activeTab == 3,
                                            })}
                                            onClick={() => {
                                                this.toggleTab(3);
                                            }}
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="32.815" height="49.055" viewBox="0 0 32.815 49.055">
                                                <path id="Path_441" data-name="Path 441" d="M32.681-43.765H24.729a13.718,13.718,0,0,1,2.729,6.671H34.23l-1.55,5.29H27.56q-.606,5.98-4.75,9T11.287-19.777L27.9-.406V0H20.249L2.392-20.882l-.034-4.185h8.389A12.034,12.034,0,0,0,17.587-26.9a7.875,7.875,0,0,0,3.335-4.9H1.415l1.55-5.29h17.89q-1.617-6.4-9.905-6.671H1.482l1.583-5.29H34.23Z" transform="translate(-1.415 49.055)" />
                                            </svg>
                                            <span>{this.state.totalAllUnpaid}</span>
                                            <label>Unpaid</label>
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xl={6}>
                        <Card className="cstm-card">
                            <CardHeader>
                                Monthly Sales
                            </CardHeader>
                            <CardBody>
                                <SplineArea />
                            </CardBody>
                        </Card>
                    </Col>

                    <Col xl={6}>
                        <Card className="cstm-card">
                            <CardHeader>
                                Quick Links
                            </CardHeader>
                            <CardBody className="d-flex justify-content-center align-items-center">
                                <ul className="qck-link">
                                    <li>
                                        <Link to="/customer_add" >
                                            <span><AddcustomerIcon /></span>
                                            <label>Add Customer</label>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/estimation_add" >
                                            <span><EstimateIcon /></span>
                                            <label>Add Estimate</label>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/invoice_add" >
                                            <span><InvoiceIcon /></span>
                                            <label>Add Invoice</label>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/product_add" >
                                            <span><AdditemIcon /></span>
                                            <label>Add Items</label>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#" >
                                            <span><AdditemIcon /></span>
                                            <label>Add Expenses</label>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#" >
                                            <span><AdditemIcon /></span>
                                            <label>Add Purchase</label>
                                        </Link>
                                    </li>
                                </ul>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-12">
                        <Card className="cstm-card">
                            <CardHeader>
                                Latest Invoices
                            </CardHeader>
                            <CardBody>

                                <TabContent
                                    activeTab={this.state.activeTab}
                                    className="text-muted"
                                >
                                    <TabPane tabId={1}>
                                        <Row>
                                            <Col sm="12">
                                                <InvoiceListAll setTotalAmount={this.setTotalAmount} isPaginationRequire={false} setDownloadpdf={this.setDownloadpdf} />
                                            </Col>
                                        </Row>
                                    </TabPane>
                                    <TabPane tabId={2}>
                                        <Row>
                                            <Col sm="12">
                                                <InvoiceListPaid setTotalAmount={this.setTotalAmount} isPaginationRequire={false} setDownloadpdf={this.setDownloadpdf} />
                                            </Col>
                                        </Row>
                                    </TabPane>
                                    <TabPane tabId={3}>
                                        <Row>
                                            <Col sm="12">
                                                <InvoiceListUnpaid setTotalAmount={this.setTotalAmount} isPaginationRequire={false} setDownloadpdf={this.setDownloadpdf} />
                                            </Col>
                                        </Row>
                                    </TabPane>
                                </TabContent>
                                <div className="d-flex justify-content-center">
                                    <Link to="/invoices" className="btn btn-primary w-md">
                                        View all invoices
                                    </Link>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

export default withRouter(DashboardContent);