import React, { Component } from "react";
import MetaTags from "react-meta-tags";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import { Card, CardBody, Col, Label, Modal, Row, Table } from 'reactstrap';
import { AvForm, AvField, AvRadioGroup, AvRadio } from "availity-reactstrap-validation";

import { post, get, del } from "src/helpers/api_helper";
import { Razorpay } from "../Authentication/Razorpay";
import { round } from "lodash";


interface Params extends RouteComponentProps {
    modal_is_open: boolean;
    openModal: React.KeyboardEventHandler<any> | React.MouseEventHandler<any> | undefined;
    closeModal: any;
    successModalPlan: any;
}

type State = {
    amount: number;
    r_key: string;
    order_id: string;
    currency: string;
    merchant_order_id: string;
    razorpay_payment_id: string;
    razorpay_order_id: string;
    razorpay_signature: string;
    is_success: number;
    is_progress: number;
    is_error: number;
    plan_name: string;
    companyName: string;
    companyEmail: string;
    companyTelephone: string;
    companyAaddress: string;
    payment: number;
    description: string;
    plan_price: number;
    gst_number: string;
};

interface response {
    razorpay_payment_id: string;
    razorpay_order_id: string;
    razorpay_signature: string;
    orderId: string;
}

interface razerpay_init {
    r_key: string;
    order_id: string;
    amount: number;
    currency: string;
    merchant_order_id: string;
}

class RenewPlan extends Component<Params, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            amount: 0,
            r_key: '',
            order_id: '',
            currency: '',
            merchant_order_id: '',
            razorpay_payment_id: '',
            razorpay_order_id: '',
            razorpay_signature: '',
            is_success: 0,
            is_progress: 0,
            is_error: 0,
            plan_name: '',
            companyName: '',
            companyEmail: '',
            companyTelephone: '',
            companyAaddress: '',
            payment: 2,
            description: '',
            plan_price: 0,
            gst_number: ''
        };
    }

    onPaymentHandler = (response: response) => {

        this.setState({
            razorpay_payment_id: response.razorpay_payment_id,
            razorpay_order_id: response.razorpay_order_id,
            razorpay_signature: response.razorpay_signature,
        });

        const sendGetRequest = async () => {
            try {
                this.setState({
                    is_progress: 1,
                });
                const resp = await post(process.env.REACT_APP_API_URL + '/api/renew_save', this.state);

                if (resp.success) {
                    this.setState({
                        is_success: 1,
                    });

                    const getAuthUser: any = localStorage.getItem("authUser");
                    const data = JSON.parse(getAuthUser);
                    data.isPlanExpired = 1;
                    localStorage.setItem("authUser", JSON.stringify(data));

                    this.props.successModalPlan();
                } else {
                    this.setState({
                        is_progress: 0,
                        is_success: 0,
                        is_error: 1,
                    });
                }

            } catch (err) {
                // Handle Error Here
                console.error(err);
            }
        };

        sendGetRequest();
    }

    makePayment = async (event: any, values: any) => {
        let amount: number = round((this.state.amount * 118 / 100), 2);
        const result = await post(process.env.REACT_APP_API_URL + '/api/razorpay_init', { amount: amount });

        if (!result) {
            console.log("Server error.");
        }

        let data: razerpay_init = result.data;
        this.setState(data);
        // this.setState({ amount: this.state.plan_price });

        const orderId = this.state.order_id;
        const appName = process.env.REACT_APP_NAME;
        const options = {
            key: data.r_key,
            amount: data.amount,
            currency: data.currency,
            name: appName,
            description: `${this.state.plan_name} Payment`,
            order_id: orderId,
            handler: this.onPaymentHandler.bind(this),
            prefill: {
                name: this.state.companyName,
                email: this.state.companyEmail,
                contact: this.state.companyTelephone,
            },
            notes: {
                address: this.state.companyAaddress,
                merchant_order_id: data.merchant_order_id
            },
            theme: {
                color: "#6266b0",
            },
        };

        const paymentObject = Razorpay(options);
        paymentObject.open();
    }

    async componentDidMount() {
        const respPer: any = await get(process.env.REACT_APP_API_URL + `/api/renew_init`);
        this.setState({
            amount: respPer.data.plan.plan_price,
            plan_price: respPer.data.plan.plan_price,
            plan_name: respPer.data.plan.plan_name,
            companyName: respPer.data.companyName,
            gst_number: respPer.data.gst_number,
            companyTelephone: respPer.data.companyTelephone,
            companyEmail: respPer.data.companyEmail,
            description: respPer.data.plan.description
        });
        const res = await this.loadScript(
            "https://checkout.razorpay.com/v1/checkout.js"
        );
    }

    loadScript(src: string) {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    }
    onChangehandler = (e: any) => {
        let name = e.target.name;
        let value = e.target.value;
        let data: any = {};
        data[name] = value;
        this.setState(data);
    }
    render() {
        return (
            <Modal
                size="xl"
                isOpen={this.props.modal_is_open}
                toggle={this.props.openModal}
                className={'md-700'}
            // className="modal-xl"
            >

                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myModalLabel">
                        Plan Expired
                    </h5>
                    <button
                        type="button"
                        onClick={() => this.props.closeModal()}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <AvForm onValidSubmit={this.makePayment} >
                        <div className="row justify-content-center">
                            <Col>
                                <div className="text-center">
                                    <div className="mb-4">
                                        <i className="mdi mdi-alert-circle-outline text-danger display-4"></i>
                                    </div>
                                    <div>
                                        <h5>Plan Expired</h5>
                                        <p className="text-secondary">
                                            Your plan has been expired. Click the following choose plan button to make payment to continue the plan.
                                        </p>
                                        <div className="p-3">
                                            <Row>
                                                <Col xl={6} sm={7}>
                                                    <Row>
                                                        <Label
                                                            htmlFor="horizontal-firstname-input"
                                                            className="col-sm-12 col-form-label"
                                                            style={{ textAlign: "left" }}
                                                        >
                                                            Company Name
                                                        </Label>
                                                        <Col sm={12}>
                                                            <AvField
                                                                name="company_name"
                                                                type="text"
                                                                placeholder="Enter Company Name"
                                                                errorMessage="Enter Company Name"
                                                                value={this.state.companyName}
                                                                readOnly={true}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col xl={6} sm={7}>
                                                    <Row>
                                                        <Label
                                                            htmlFor="horizontal-firstname-input"
                                                            className="col-sm-12 col-form-label"
                                                            style={{ textAlign: "left" }}
                                                        >
                                                            GSTIN
                                                        </Label>
                                                        <Col sm={12}>
                                                            <AvField
                                                                name="gst_number"
                                                                type="text"
                                                                placeholder="Enter GSTIN"
                                                                errorMessage="Enter GSTIN"
                                                                value={this.state.gst_number}
                                                                validate={{ required: { value: true } }}
                                                                onChange={this.onChangehandler}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </div>
                        <div className="row justify-content-center">
                            <Col xl={12} sm={14}>
                                <Card className="mb-xl-0 bg-light">
                                    <CardBody>
                                        <div className="p-2">
                                            <div className="pricing-badge">
                                                <span className="badge" style={{ backgroundColor: '#1D6F42', color: '#fff' }}>Featured</span>
                                            </div>
                                            <Row>
                                                <Col xl={6} sm={7} className="border border-secondary p-2">
                                                    <h5 className="font-size-16 text-secondary">{this.state.plan_name}</h5>
                                                    <h1 className="mt-3">₹ {this.state.plan_price}
                                                        <span className="text-secondary font-size-16 fw-medium">+ GST / year</span>
                                                    </h1>
                                                    <p className="text-secondary mt-3 font-size-15">{this.state.description}</p>
                                                   
                                                </Col>
                                                <Col xl={6} sm={7} className="border border-secondary p-2">
                                                    <div className="text-secondary">
                                                        <p className="font-size-15"><i className="mdi mdi-check-circle text-secondary font-size-18 me-2"></i>Unlimited Documents</p>
                                                        <p className="font-size-15"><i className="mdi mdi-check-circle text-secondary font-size-18 me-2"></i>Unlimited Users</p>
                                                        <p className="font-size-15"><i className="mdi mdi-check-circle text-secondary font-size-18 me-2"></i>Recurring Invoices</p>
                                                        <p className="font-size-15"><i className="mdi mdi-check-circle text-secondary font-size-18 me-2"></i>Multi-Currency</p>
                                                        <p className="font-size-15"><i className="mdi mdi-check-circle text-secondary font-size-18 me-2"></i>Payment Reminders</p>

                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row xl={12} sm={14}>
                                                <div className="mt-4 pt-2">
                                                    {/* <a className="btn btn-primary w-100" href="#" >Choose Plan</a> */}
                                                    <button className="btn btn-primary w-50"  >
                                                        Choose Plan
                                                    </button>
                                                </div>
                                            </Row>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </div>
                    </AvForm>
                </div>
                <div className="modal-footer">
                    <button
                        type="button"
                        onClick={() => {
                            this.props.closeModal()
                        }}
                        className="btn btn-secondary "
                        data-dismiss="modal"
                    >
                        Close
                    </button>
                    {/* <button type="button" className="btn btn-primary " onClick={() => this.makePayment()} >
                        Make Payment
                    </button> */}
                </div>
            </Modal>
        )
    }
}

export default withRouter(RenewPlan);
