import React, { Component } from "react";
import MetaTags from "react-meta-tags";
import { Container } from "reactstrap";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import { Row, Col, Card, CardBody, CardTitle, CardHeader, Button } from "reactstrap";
import { AvForm, AvField, AvRadioGroup, AvRadio } from "availity-reactstrap-validation";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import { get, post } from "src/helpers/api_helper";

interface Params {
    id: string;
}

type RoleState = {
    id: string;
    bank_name: string;
    account_no: string;
    account_name: string;
    ifsc_code: string;
    permissions: Array<any>;
    isProgress: number;
};

class BankModify extends Component<RouteComponentProps<Params>, RoleState> {
    constructor(props: any) {
        super(props);
        const { id } = this.props.match.params;
        this.state = {
            id: id,
            bank_name: "",
            account_no: "",
            account_name: "",
            ifsc_code: "",
            permissions: [],
            isProgress: 0
        };
    }

    resetForm = () => {

        this.props.history.push("/banks");
    }
    onChangehandler = (e: any) => {
        let name = e.target.name;
        let value = e.target.value;
        let data: any = {};
        data[name] = value;
        this.setState(data);
    }
    handleValidSubmit = (event: any, values: any) => {
        this.setState({ isProgress: 1 });
        values["id"] = this.state.id;
        // console.log(values);
        const resp = post(process.env.REACT_APP_API_URL + '/api/bank_save', values);
        this.props.history.push("/banks");
        this.setState({ isProgress: 0 });
    }

    async componentDidMount() {
        try {
            const respPer: any = await get(process.env.REACT_APP_API_URL + `/api/permissions/7`);
            this.setState({ permissions: respPer.data });
            const permission = this.state.permissions.find(({ action, is_active }) => action == "modify" && is_active == 1);

            if (!permission) {
                this.props.history.push("/banks");
            }
            const respP: any = await get(process.env.REACT_APP_API_URL + '/api/bank_get/' + this.state.id);
            this.setState(respP.data);

        } catch (err) {
            // Handle Error Here
            console.error(err);
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Banks</title>
                    </MetaTags>
                    <div className="container-fluid">
                        <Breadcrumbs title="Create Bank" breadcrumbItem="Create Bank" />

                        <Row>
                            <Col className="col-12">
                                <Card>

                                    <CardHeader></CardHeader>



                                    <CardBody>

                                        <AvForm onValidSubmit={this.handleValidSubmit}>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="mb-3">
                                                        <label htmlFor="progresspill-firstname-input">
                                                            Bank name
                                                        </label>
                                                        <AvField
                                                            name="bank_name"
                                                            type="text"
                                                            placeholder="Enter Bank name"
                                                            errorMessage="Enter Bank name"
                                                            value={this.state.bank_name}
                                                            onChange={this.onChangehandler}
                                                            validate={{ required: { value: true } }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="mb-3">
                                                        <label htmlFor="progresspill-address-input">
                                                            Account No
                                                        </label>
                                                        <AvField
                                                            name="account_no"
                                                            type="text"
                                                            placeholder="Enter Account No"
                                                            errorMessage="Enter Account No"
                                                            value={this.state.account_no}
                                                            onChange={this.onChangehandler}
                                                            validate={{ required: { value: true } }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="mb-3">
                                                        <label htmlFor="progresspill-lastname-input">
                                                            Account Name
                                                        </label>
                                                        <AvField
                                                            name="account_name"
                                                            type="text"
                                                            placeholder="Enter account name"
                                                            errorMessage="Enter account name"
                                                            value={this.state.account_name}
                                                            onChange={this.onChangehandler}
                                                            validate={{ required: { value: true } }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="mb-3">
                                                        <label htmlFor="progresspill-lastname-input">
                                                            IFSC Code
                                                        </label>
                                                        <AvField
                                                            name="ifsc_code"
                                                            type="text"
                                                            placeholder="Enter rate"
                                                            errorMessage="Enter rate"
                                                            value={this.state.ifsc_code}
                                                            onChange={this.onChangehandler}
                                                            validate={{ required: { value: true } }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="d-flex flex-wrap gap-2">
                                                {this.state.isProgress ?
                                                    <>
                                                        <button type="submit" className="btn btn-primary " disabled={true}>
                                                            <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>Submiting
                                                        </button>
                                                    </> :
                                                    <>
                                                        <Button type="submit" color="primary" className="">
                                                            Submit
                                                        </Button>{" "}
                                                        <Button type="reset" color="secondary" className="" onClick={this.resetForm}>
                                                            Cancel
                                                        </Button>
                                                    </>
                                                }
                                            </div>
                                        </AvForm>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div></div>
            </React.Fragment>
        )
    }
}

export default withRouter(BankModify);