import React, { Component } from "react";
import MetaTags from "react-meta-tags";
import { Container } from "reactstrap";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import { Row, Col, Card, CardBody, CardTitle, CardHeader, Button } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import { get, post } from "src/helpers/api_helper";

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

interface RoleParams {

}

type RoleState = {
    roleId: string;
    code: string;
    role: string;
    description: string;
    permissions: Array<any>;
    loading: boolean;
    isProgress: number;
};

class RoleCreate extends Component<RouteComponentProps<RoleParams>, RoleState> {
    constructor(props: any) {
        super(props);
        this.state = {
            roleId: "0",
            code: "",
            role: "",
            description: "",
            loading: true,
            permissions: [],
            isProgress: 0 
        };
    }

    resetForm = () => {

        this.props.history.push("/roles");
    }
    onChangehandler = (e: any) => {
        let name = e.target.name;
        let value = e.target.value;
        let data: any = {};
        data[name] = value;
        this.setState(data);
    }
    async componentDidMount() {
        const respPer: any = await get(process.env.REACT_APP_API_URL + `/api/permissions/5`);
        this.setState({ permissions: respPer.data });
        const permission = this.state.permissions.find(({ action, is_active }) => action == "create" && is_active == 1);

        if (!permission) {
            this.props.history.push("/roles");
        }

        this.setState({ loading: false });
    }
    handleValidSubmit = (event: any, values: any) => {
        this.setState({ isProgress: 1 });
        values["roleId"] = this.state.roleId;
        // console.log(values);
        const resp = post(process.env.REACT_APP_API_URL + '/api/role_save', values);
        this.props.history.push("/roles");
        this.setState({ isProgress: 0 });
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Roles</title>
                    </MetaTags>
                    <div className="container-fluid">
                        <Breadcrumbs title="Create Role" breadcrumbItem="Create Role" />

                        <Row>
                            <Col className="col-12">
                                <Card>

                                    <CardHeader></CardHeader>



                                    <CardBody>

                                        <AvForm onValidSubmit={this.handleValidSubmit}>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="mb-3">
                                                        {this.state.loading ?
                                                            <><Skeleton width={100} /><Skeleton height={37} /></> :
                                                            <>
                                                                <label htmlFor="progresspill-firstname-input">
                                                                    Code
                                                                </label>
                                                                <AvField
                                                                    name="code"
                                                                    type="text"
                                                                    placeholder="Enter code"
                                                                    errorMessage="Enter code"
                                                                    value={this.state.code}
                                                                    onChange={this.onChangehandler}
                                                                    validate={{ required: { value: true } }}
                                                                />
                                                            </>}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="mb-3">
                                                        {this.state.loading ?
                                                            <><Skeleton width={100} /><Skeleton height={37} /></> :
                                                            <>
                                                                <label htmlFor="progresspill-lastname-input">
                                                                    Role
                                                                </label>
                                                                <AvField
                                                                    name="role"
                                                                    type="text"
                                                                    placeholder="Enter role"
                                                                    errorMessage="Enter role"
                                                                    value={this.state.role}
                                                                    onChange={this.onChangehandler}
                                                                    validate={{ required: { value: true } }}
                                                                />
                                                            </>}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="mb-3">
                                                        {this.state.loading ?
                                                            <><Skeleton width={100} /><Skeleton height={37} /></> :
                                                            <>
                                                                <label htmlFor="progresspill-address-input">
                                                                    Description
                                                                </label>
                                                                <AvField
                                                                    name="description"
                                                                    type="textarea"
                                                                    placeholder="Enter description"
                                                                    errorMessage="Enter description"
                                                                    value={this.state.description}
                                                                    onChange={this.onChangehandler}
                                                                    validate={{ required: { value: false } }}
                                                                />
                                                            </>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex flex-wrap gap-2">
                                                {this.state.loading ?
                                                    <><Skeleton width={100} /><Skeleton height={37} /></> :
                                                    <>
                                                        {this.state.isProgress ?
                                                            <>
                                                                <button type="submit" className="btn btn-primary " disabled={true}>
                                                                    <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>Submiting
                                                                </button>
                                                            </> :
                                                            <>
                                                                <Button type="submit" color="primary" className="">
                                                                    Submit
                                                                </Button>{" "}
                                                                <Button type="reset" color="secondary" className="" onClick={this.resetForm}>
                                                                    Cancel
                                                                </Button>
                                                            </>
                                                        }
                                                    </>}
                                            </div>
                                        </AvForm>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div></div>
            </React.Fragment>
        )
    }
}

export default withRouter(RoleCreate);