import React, { useEffect,useState } from "react";
import MetaTags from "react-meta-tags";
import { Row, Col, Alert, Container, Button } from "reactstrap";

// availity-reactstrap-validation
import { AvForm, AvField, AvRadioGroup, AvRadio } from "availity-reactstrap-validation";

// action
// import { registerUser, apiError } from "../../store/actions";

//redux
// import { useSelector, useDispatch } from "react-redux";

import { Link } from "react-router-dom";

// import images
import logo from "../../assets/images/logo_login.png";
import CarouselPageNew from "./CarouselPageNew";

import axios from 'axios';

const Register = () => {
  // const dispatch = useDispatch();
  const [data, setData] = useState({ success : null, message : "" , data : { exist : null } }); 
  const [firstName, setFirstName] = useState(""); 
  const [secondName, setSecondName] = useState("");
  const [email, setEmail] = useState("");
  const [gender, setGender] = useState("");
  const [isProgress, setIsProgress] = useState(0); 
  const [isSending, setIsSending] = useState(0); 

  const appName = process.env.REACT_APP_NAME;  


  // handleValidSubmit
  const handleValidSubmit = (values: any) => {    
    setIsProgress(1);
    const sendPostRequest = async () => {
        try {

          const resp = await axios.post(process.env.REACT_APP_API_URL + '/api/register', values);            
          setData(resp.data);       
          
          if (resp.data.success === false) {
            if (resp.data.data.exist === 2) {
              
            }
            setIsProgress(0);
          }

        } catch (err) {
            // Handle Error Here
            console.error(err);
        }
    };

    sendPostRequest(); 
    // dispatch(registerUser(values));
  };

/*   useEffect(() => {
    dispatch(apiError(""));
  }, [dispatch]); */

  async function handleResend() {
    setIsSending(1);
    try {

      const resp = await axios.post(process.env.REACT_APP_API_URL + '/api/resend_mail', {'email': email});            
      setData(resp.data);        
      
        setIsSending(0);
     

    } catch (err) {
        // Handle Error Here
        console.error(err);
    }
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Register {appName}</title>
      </MetaTags>
      <div className="auth-page">
        <Container fluid className="p-0">
          <Row className="g-0">
            <CarouselPageNew />
            <Col lg={4} md={5} className="col-xxl-4">
              <div className="auth-full-page-content d-flex p-sm-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5 text-center">
                      <Link to="/" className="d-block auth-logo">
                      
                      <img className="img-fluid" src={logo} alt="" />
{" "}
                      </Link>
                    </div>
                    { (data.success == true || data.data.exist === 2 ) ? (
                    <div className="auth-content my-auto">
                      <div className="text-center">
                        <div className="avatar-lg mx-auto">
                          <div className="avatar-title rounded-circle bg-light">
                            <i className="bx bx-mail-send h2 mb-0 text-primary"></i>
                          </div>
                        </div>
                        <div className="p-2 mt-4">
                          <h4>Verify your email</h4>
                          {  data.data.exist === 2 ? (
                            <p dangerouslySetInnerHTML={{ __html: data.message }} ></p>
                          ) : (
                            <p>
                              We have sent you verification email to {" "}
                              <span className="fw-bold">{email}</span>,
                              Please check it
                            </p>
                          )}


                          <div className="mt-4">
                            <Link
                              to="/"
                              className="btn btn-primary w-10"
                            >
                              Back to Home
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="mt-5 text-center">
                        <p className="text-muted mb-0">
                          Did't receive an email ?{" "}
                          <Link to="#" className="fw-semibold" onClick={handleResend}>
                            {" "}
                            Resend { isSending ? (
                              <div className="spinner-border spinner-border-sm" role="status">
                              <span className="sr-only">Loading...</span>
                            </div>
                            ) :null } {" "}
                          </Link>{" "}
                        </p>
                      </div>
                    </div>
                    ) : (
                    <div className="auth-content my-auto">
                      <div className="text-center">
                        <h5 className="mb-0">Register Account</h5>
                        <p className="text-muted mt-2">
                          Get your {appName} account now.
                        </p>
                      </div>
                      <AvForm
                        className="needs-validation custom-form mt-4 pt-2"
                        onValidSubmit={(e: any, v: any) => {
                          handleValidSubmit(v);
                        }}
                      >
                      
                          {/* <Alert color="success">
                            Register User Successfully
                          </Alert>   */}                  

                          {data.success === false ? (
                          <Alert color="danger"> 
                            <div dangerouslySetInnerHTML={{ __html: data.message }} ></div>                 
                          </Alert>
                          ) : null }

                        <div className="mb-3">
                          <AvField
                            name="firstName"
                            label="First Name"
                            type="text"
                            value={firstName} 
                            onChange={(e:any) => setFirstName(e.target.value)}
                            required
                            placeholder="Enter First Name"
                          />
                        </div>

                        <div className="mb-3">
                          <AvField
                            name="secondName"
                            label="Second Name"
                            type="text"
                            value={secondName} 
                            onChange={(e:any) => setSecondName(e.target.value)}
                            required
                            placeholder="Enter Second Name"
                          />
                        </div>

                        <div className="mb-3">
                          <AvField
                            id="email"
                            name="email"
                            label="Email"
                            className="form-control"
                            placeholder="Enter email"
                            type="email"
                            value={email} 
                            onChange={(e:any) => setEmail(e.target.value)}
                            required
                          />
                        </div>

                        {/* <div className="mb-3">
                        <AvRadioGroup inline name="gender" label="Gender" value={gender} onChange={(e:any) => setGender(e.target.value)} required>
                          <AvRadio label="Male" value="M" />
                          <AvRadio label="Female" value="F" />                          
                        </AvRadioGroup>
                        </div> */}
                        <div className="mb-4">
                          <p className="mb-0">
                            By registering you agree to the {appName}{" "}
                            <Link to="#">
                              Terms of Use
                            </Link>
                          </p>
                        </div>
                        <div className="mb-3">
                          { isProgress === 1 ?
                          (
                          <button
                            className="btn blk-btn w-100 waves-effect waves-light"
                            type="submit" disabled={true}
                          >
                            <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>{" "}Submiting
                          </button>
                          ) : (
                          <button
                            className="btn blk-btn w-100 waves-effect waves-light"
                            type="submit"
                          >
                            Register
                          </button>
                          )
                          }

                        </div>
                      </AvForm>

                      <div className="mt-5 text-center">
                        <p className="text-muted mb-0">
                          Already have an account ?{" "}
                          <Link
                            to="/login"
                            className="fw-semibold"
                          >
                            {" "}
                            Login{" "}
                          </Link>{" "}
                        </p>
                      </div>
                    </div>
                    )}
                    <div className="mt-4 mt-md-5 text-center">
                      <p className="mb-0">
                        © {new Date().getFullYear()} {appName}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Register;
