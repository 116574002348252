import PropTypes from "prop-types";
import React, { useEffect, useRef, useCallback, useState } from "react";

//Import Icons
import Icon from "@ailibs/feather-react-ts";

// //Import Scrollbar
import SimpleBar from "simplebar-react";

//Import images
import giftBox from "../../assets/images/features.svg";

//i18n
import { withTranslation } from "react-i18next";

// MetisMenu
import MetisMenu from "metismenujs";
import { useLocation, withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import UpgradePlan from "src/pages/Payments/UpgradePlan";
import { get } from "src/helpers/api_helper";

import banner from "../../assets/images/banner.png";

const SidebarContent = (props: any) => {
  const ref = useRef<any>();
  const [isPlanExpired, setIsPlanExpired] = useState(0);
  const [modalPlan, setModalPlan] = useState(false);
  const [planPrice, setPlanPrice] = useState(0);

  const openModalPlan = () => {
    setModalPlan(true);
  }

  const closeModalPlan = () => {
    setModalPlan(false);
  }

  const successModalPlan = () => {
    setModalPlan(false);
    setIsPlanExpired(1);

    const getAuthUser: any = localStorage.getItem("authUser");
    const objUser = JSON.parse(getAuthUser);
    objUser.isPlanExpired = 1;
    localStorage.setItem("authUser", JSON.stringify(objUser));
  }

  const activateParentDropdown = useCallback(item => {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    let pathName = props.location.pathname;    
    //new code active for non listing menu
    if (pathName === '/invoice_add' || pathName.includes('/invoice_edit')) {
      pathName = '/invoices';
    }
    if (pathName === '/estimation_add' || pathName.includes('/estimation_edit') || pathName.includes('/estimation_details')) {
      pathName = '/estimations';
    }
    if (pathName === '/customer_add' || pathName.includes('/customer_edit')) {
      pathName = '/customers';
    }
    if (pathName === '/product_add' || pathName.includes('/product_edit')) {
      pathName = '/products';
    }
    if (pathName === '/role_add' || pathName.includes('/role_edit') || pathName.includes('/permissions')) {
      pathName = '/roles';
    }
    if (pathName === '/user_add' || pathName.includes('/user_edit')) {
      pathName = '/users';
    }
    if (pathName === '/bank_add' || pathName.includes('/bank_edit')) {
      pathName = '/banks';
    }
    if (pathName === '/exp_category_add' || pathName.includes('/exp_category_edit')) {
      pathName = '/exp_categories';
    }
    if (pathName === '/exp_item_add' || pathName.includes('/exp_item_edit')) {
      pathName = '/exp_items';
    }
    if (pathName === '/vendor_add' || pathName.includes('/vendor_edit')) {
      pathName = '/vendors';
    }
    if (pathName === '/expense_add' || pathName.includes('/expense_edit') || pathName === '/purchase_add' || pathName.includes('/purchase_edit')) {
      pathName = '/expenses';
    }
    const initMenu = () => {
      new MetisMenu("#side-menu");
      let matchingMenuItem = null;
      const ul: any = document.getElementById("side-menu");
      const items = ul.getElementsByTagName("a");
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, [props.location.pathname, activateParentDropdown]);

  useEffect(() => {
    ref.current.recalculate();
  });

  function scrollElement(item: any) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  useEffect(() => {
    const getAuthUser: any = localStorage.getItem("authUser");
    const obj = JSON.parse(getAuthUser);
    setIsPlanExpired(obj.isPlanExpired);

    const sendGetRequest = async () => {
      try {

        const respPer: any = await get(process.env.REACT_APP_API_URL + `/api/renew_init`);
        setPlanPrice(+respPer.data.plan.plan_price);

      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };

    sendGetRequest();

  }, []);

  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {/* <li className="menu-title">{props.t("Menu")} </li>
            <li>
              <Link to="/dashboard" className="">
                <Icon name="home" />
                <span>{props.t("Dashboard")}</span>
              </Link>
            </li> */}
            <li>
              <Link to="/#" className="has-arrow">
                <Icon name="shopping-bag" />
                <span>{props.t("Sales")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/invoices">{props.t("Invoice")}</Link>
                </li>
                <li>
                  <Link to="/estimations">{props.t("Estimate")}</Link>
                </li>
                <li>
                  <Link to="/customers">{props.t("Customer")}</Link>
                </li>
                <li>
                  <Link to="/products">{props.t("Items")}</Link>
                </li>
                <li>
                  <Link to="/#">{props.t("Reports")}</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/#" className="has-arrow">
                <Icon name="grid" />
                <span>{props.t("Expense")}</span>
              </Link>
              <ul className="sub-menu">
                {/*  <li>
                  <Link to="/#">{props.t("Add expense")}</Link>
                </li>
                <li>
                  <Link to="/#">{props.t("Add purchase bill")}</Link>
                </li> */}
                <li>
                  <Link to="/expenses">{props.t("Expenses")}</Link>
                </li>
                <li>
                  <Link to="/vendors">{props.t("Vendor")}</Link>
                </li>
                <li>
                  <Link to="/exp_items">{props.t("Items")}</Link>
                </li>
                <li>
                  <Link to="/exp_categories">{props.t("Categories")}</Link>
                </li>

                {/* <li>
                  <Link to="/#">{props.t("Vendor")}</Link>
                </li>
                <li>
                  <Link to="/#">{props.t("Items")}</Link>
                </li>
                <li>
                  <Link to="/#">{props.t("Categories")}</Link>
                </li> */}
              </ul>
            </li>
            <li>
              <Link to="/#" className="has-arrow">
                <Icon name="activity" />
                <span>{props.t("Payroll")}</span>
              </Link>
              {/* <ul className="sub-menu">
                <li>
                  <Link to="/paroll">{props.t("paroll")}</Link>
                </li>                
              </ul> */}
            </li>
            <li>
              <Link to="/#" className="has-arrow">
                <Icon name="package" />
                <span>{props.t("Workspace")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/#">{props.t("GST Return")}</Link>
                </li>
                <li>
                  <Link to="/#">{props.t("GST LUT")}</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/#" className="">
                <Icon name="pie-chart" />
                <span>{props.t("Reports")}</span>
              </Link>
              <ul className="sub-menu">

              </ul>
            </li>
            <li>
              <Link to="/#" className="has-arrow">
                <Icon name="tool" />
                <span>{props.t("Settings")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/company-profile">{props.t("General Settings")}</Link>
                </li>
                <li>
                  <Link to="/users">{props.t("Users")}</Link>
                </li>
                <li>
                  <Link to="/roles">{props.t("Roles and Permissions")}</Link>
                </li>
                <li>
                  <Link to="/banks">{props.t("Banks")}</Link>
                </li>
              </ul>
            </li>
          </ul>
          {/*<div className="card sidebar-alert border-0 text-center mx-4 mb-0 mt-5">
            <div className="card-body">

               <div className="package">
                <h2>Yearly</h2>
                <h1>₹ {planPrice.toFixed(0)}</h1>
                
                  <a href="#" onClick={openModalPlan} className="btn mt-2">
                    Upgrade Now
                  </a>
               
              </div> 


            </div>
          </div>*/}
          <div className="text-center mx-4 mb-0 mt-5">
            <a href="#" onClick={openModalPlan} className="mt-2">
              <img className="img-fluid" src={banner} alt="" />
            </a>
          </div>
          <UpgradePlan modal_is_open={modalPlan} openModal={openModalPlan} closeModal={closeModalPlan} successModalPlan={successModalPlan} />
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withTranslation()(withRouter(SidebarContent));
