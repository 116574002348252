import React, { Component } from "react";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import { Row, Col, Card, CardBody, CardTitle, CardHeader, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Table } from "reactstrap";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

//Import Breadcrumb
import "../Datatable/datatables.scss";
import { del, get, post } from "src/helpers/api_helper";
import NoDataIndication from "../Datatable/NoDataIndication";

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

interface MyProps extends RouteComponentProps<any> {

}

interface MyState {
    sizePerPage: number;
    totalSize: number;
    data: any;
    sortField: string;
    sortOrder: string;
    permissions: Array<any>;
    loading: boolean;
};

interface ChangeProp {
    page: number;
    sizePerPage: number;
}

class BankList extends Component<MyProps, MyState> {
    constructor(props: any) {
        super(props);
        this.state = {
            sizePerPage: 10,
            totalSize: 0,
            data: [],
            sortField: "id",
            sortOrder: "asc",
            loading: true,
            permissions: []
        };
        this.handleDelete = this.handleDelete.bind(this);
        this.fetchData = this.fetchData.bind(this);
    }

    fetchData = (param: any) => {
        const sendGetRequest = async () => {
            try {

                const resp = await post(process.env.REACT_APP_API_URL + `/api/bank_list_grid`, param);
                // const respPer = await get(process.env.REACT_APP_API_URL + `/api/permissions/7`);
                if (resp.success) {
                    this.setState({
                        totalSize: resp.data.count,
                        data: resp.data.results,
                        // permissions: respPer.data
                    });
                }

            } catch (err) {
                // Handle Error Here
                console.error(err);
            }
        };

        sendGetRequest();
    }

    componentDidMount() {
        const sendGetRequest = async () => {
            try {
                const respPer = await get(process.env.REACT_APP_API_URL + `/api/permissions/7`);
                if (respPer.success) {
                    this.setState({
                        permissions: respPer.data
                    });
                    this.fetchData({ page: 1, searchText: "", sizePerPage: this.state.sizePerPage, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
                }
            } catch (err) {
                // Handle Error Here
                console.error(err);
            }
        };
        sendGetRequest();
        this.setState({ loading: false });
    }

    handleTableChange = (type: string, param: ChangeProp) => {

        this.setState({ sizePerPage: param.sizePerPage });
        this.fetchData(param);

    }

    handleDelete = (row: any) => {
        const sendGetRequest = async () => {
            try {

                const conf = window.confirm("Do you want to delete this record?");
                if (conf) {
                    const resp = del(process.env.REACT_APP_API_URL + '/api/bank_delete/' + row.id);

                }

            } catch (err) {
                // Handle Error Here
                console.error(err);
            }
        };

        sendGetRequest();
        this.fetchData({ page: 1, searchText: "", sizePerPage: this.state.sizePerPage, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
    }

    onDefaultChangehandler = (e: any) => {        
        let value = e.target.value;
        let checked = e.target.checked;
        const sendGetRequest = async () => {
            try {                
                const resp: any = await get(process.env.REACT_APP_API_URL + '/api/default_bank_set/'+value);
                this.fetchData({ page: 1, searchText: "", sizePerPage: this.state.sizePerPage, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
            } catch (err) {
                // Handle Error Here
                console.error(err);
            }
        };

        sendGetRequest();
    }

    render() {

        const pageOptions: any = {
            sizePerPage: this.state.sizePerPage,
            totalSize: this.state.totalSize, // replace later with size(customers),
            custom: true,
        };

        const { SearchBar } = Search;

        const defaultSorted: any = [
            {
                dataField: this.state.sortField,
                order: this.state.sortOrder,
            },
        ];

        const columns = [
            {
                dataField: "id",
                text: "Id",
                sort: true,
                hidden: true
            },
            {
                dataField: "bank_name",
                text: "Bank Name",
                sort: true,
            },
            {
                dataField: "account_no",
                text: "Account No",
                sort: true,
            },
            {
                dataField: "account_name",
                text: "Account Name",
                sort: true,
            },
            {
                dataField: "ifsc_code",
                text: "IFSC Code",
                sort: true,
            },
            {
                dataField: "is_default",
                text: "Default",
                sort: true,
                formatter: (isDefault: number, row: any) => {
                    return (
                        <div className="form-check form-switch mb-3" dir="ltr">
                            <input type="checkbox" className="form-check-input" id="customSwitch1" defaultChecked={isDefault ? true : false} value={row.id} onChange={this.onDefaultChangehandler} />                            
                        </div> 
                    )
                }
            },
            {
                dataField: "actions",
                text: "Actions",
                isDummyField: true,
                formatter: (cellContent: any, row: any) => {
                    return (
                        <UncontrolledDropdown className="mt-4 mt-sm-0">
                            <DropdownToggle tag="a">
                                <i className="fas fa-bars"></i>
                            </DropdownToggle>

                            <DropdownMenu>
                                {this.state.permissions.find(({ action, is_active }) => action == "modify" && is_active == 1) &&
                                    (
                                        <Link to={"/bank_edit/" + row.id} >
                                            <DropdownItem to="#" ><i className="fas fa-edit" data-toggle="tooltip" data-placement="bottom" title="Edit" style={{ color: '#28a745' }} /> Edit</DropdownItem>
                                        </Link>
                                    )}
                                {this.state.permissions.find(({ action, is_active }) => action == "delete" && is_active == 1) &&
                                    (
                                        <DropdownItem to="#" onClick={() => this.handleDelete(row)} ><i className="fas fa-trash-alt" data-toggle="tooltip" data-placement="bottom" title="Delete" style={{ color: '#dc3545' }} /> Delete</DropdownItem>
                                    )}
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    );
                }
            }
        ];

        return (
            <React.Fragment>
                <Row>
                    <Col className="col-12">
                        <Card>
                            {this.state.permissions.find(({ action, is_active }) => action == "create" && is_active == 1) &&
                                (
                                    <CardHeader>
                                        {this.state.loading == true ? (
                                            <Skeleton width={100} height={40} style={{ float: "right" }} />
                                        ) : (
                                            <Link to="/bank_add" >
                                                <button type="button" className="btn btn-primary" style={{ float: "right" }}><i className="fa fa-plus"></i> Create</button>
                                            </Link>
                                        )}
                                    </CardHeader>
                                )
                            }

                            <CardBody>
                                {this.state.loading == true ? (
                                    <>
                                        <CardTitle className="h4"><Skeleton height={'10%'} width={'10%'} /></CardTitle>
                                        <Table className="table mb-0">
                                            <thead>
                                                <tr>
                                                    <th><Skeleton width={100} /></th>
                                                    <th><Skeleton width={100} /></th>
                                                    <th><Skeleton width={100} /></th>
                                                    <th><Skeleton width={100} /></th>
                                                    <th><Skeleton width={100} /></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td><Skeleton height={20} /></td>
                                                    <td><Skeleton height={20} /></td>
                                                    <td><Skeleton height={20} /></td>
                                                    <td><Skeleton height={20} /></td>
                                                    <td><Skeleton height={20} /></td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </>
                                ) :
                                    (
                                        <>
                                            <CardTitle className="h4">Bank List</CardTitle>

                                            <PaginationProvider
                                                pagination={paginationFactory(pageOptions)}
                                            >
                                                {({ paginationProps, paginationTableProps }) => (
                                                    <ToolkitProvider
                                                        keyField="id"
                                                        columns={columns}
                                                        data={this.state.data}
                                                        search
                                                    >
                                                        {toolkitProps => (
                                                            <React.Fragment>
                                                                <Row className="mb-2">
                                                                    <Col md="4">
                                                                        <div className="search-box me-2 mb-2 d-inline-block">
                                                                            <div className="position-relative">
                                                                                <SearchBar {...toolkitProps.searchProps} />
                                                                                {/* <i className="bx bx-search-alt search-icon" /> */}
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                </Row>

                                                                <Row>
                                                                    <Col xl="12">
                                                                        <div className="table-responsive">
                                                                            <BootstrapTable
                                                                                // responsive                                  
                                                                                remote
                                                                                bordered={false}
                                                                                striped={false}
                                                                                defaultSorted={defaultSorted}
                                                                                classes={"table align-middle table-nowrap"}
                                                                                headerWrapperClasses={"thead-light"}
                                                                                onTableChange={this.handleTableChange}
                                                                                {...toolkitProps.baseProps}
                                                                                {...paginationTableProps}
                                                                                noDataIndication={() => <NoDataIndication />}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                </Row>

                                                                <Row className="align-items-md-center mt-30">
                                                                    <Col className="inner-custom-pagination d-flex">
                                                                        <div className="d-inline">
                                                                            <SizePerPageDropdownStandalone
                                                                                {...paginationProps}
                                                                            />
                                                                        </div>
                                                                        <div className="text-md-right ms-auto">
                                                                            <PaginationListStandalone
                                                                                {...paginationProps}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </React.Fragment>
                                                        )}
                                                    </ToolkitProvider>
                                                )}
                                            </PaginationProvider>
                                        </>
                                    )
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

export default withRouter(BankList);