import React, { useCallback, useState } from "react";

import { Row, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import MetaTags from "react-meta-tags";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import UserList from "./UserList";


const Users = () => {  

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Users</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumbs title="Users" breadcrumbItem="User List" />
          <Row>
          
          <UserList />
          </Row>
        </div>
      </div>      
    </React.Fragment>
  );
};

export default Users;
