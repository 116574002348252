import React from 'react';

const Estimate2Icon = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44">
      <g id="icons_add_esimates" transform="translate(45 29.3)">
        <g id="Rectangle_2" data-name="Rectangle 2" transform="translate(-45 -29.3)" fill="#fff" stroke="#707070" stroke-width="1" opacity="0">
          <rect width="44" height="44" stroke="none" />
          <rect x="0.5" y="0.5" width="43" height="43" fill="none" />
        </g>
        <g id="Group_3" data-name="Group 3" transform="translate(-43 -27)">
          <g id="Group_2" data-name="Group 2">
            <path id="Path_6" data-name="Path 6" d="M26.3,30.2c.1,0,.3.2.6.2a5.9,5.9,0,0,0,1.4.2c.5,0,.7-.4.8-.8.1-.5-.3-.7-.6-.8-.6-.3-1.2-.5-1.8-.8a2.45,2.45,0,0,1,0-4.3,1.024,1.024,0,0,0,.5-.6,1.063,1.063,0,0,1,.9-.8.9.9,0,0,1,1,.7.642.642,0,0,0,.5.5c.2.1.5.3.7.4a1.09,1.09,0,0,1,.2,1.4,1.058,1.058,0,0,1-1.4.2,1.8,1.8,0,0,0-1-.2.664.664,0,0,0-.5.4.55.55,0,0,0,.3.5c.4.2.9.4,1.4.6a2.679,2.679,0,0,1,1.9,2.4,2.838,2.838,0,0,1-1.6,2.8c-.4.3-.6.5-.6.8a.876.876,0,0,1-.9.8,1.053,1.053,0,0,1-1-.8.642.642,0,0,0-.5-.5,2.384,2.384,0,0,1-1.1-.6.8.8,0,0,1-.3-1.1A1.059,1.059,0,0,1,26.3,30.2Z" fill="#1d6f41" />
          </g>
          <path id="Path_7" data-name="Path 7" d="M28,37.3a9,9,0,1,1,9-9A9.026,9.026,0,0,1,28,37.3Zm0-15.7a6.7,6.7,0,1,0,6.7,6.7A6.7,6.7,0,0,0,28,21.6Z" fill="#1d6f41" />
          <path id="Path_8" data-name="Path 8" d="M10.2,19.3h-1a1.11,1.11,0,0,1-1.1-1.1A1.2,1.2,0,0,1,9.2,17h.9a1.156,1.156,0,0,1,.1,2.3Z" fill="#1d6f41" />
          <path id="Path_9" data-name="Path 9" d="M15.5,19.3h-.9a1.11,1.11,0,0,1-1.1-1.1A1.2,1.2,0,0,1,14.6,17h.9a1.11,1.11,0,0,1,1.1,1.1A1.131,1.131,0,0,1,15.5,19.3Z" fill="#1d6f41" />
          <path id="Path_10" data-name="Path 10" d="M10.2,24.5h-1a1.1,1.1,0,1,1,0-2.2h.9a1.11,1.11,0,0,1,1.1,1.1A.972.972,0,0,1,10.2,24.5Z" fill="#1d6f41" />
          <path id="Path_11" data-name="Path 11" d="M15.5,24.5h-.9a1.1,1.1,0,1,1,0-2.2h.9a1.1,1.1,0,1,1,0,2.2Z" fill="#1d6f41" />
          <path id="Path_12" data-name="Path 12" d="M10.2,30.2h-1a1.1,1.1,0,1,1,0-2.2h.9a1.11,1.11,0,0,1,1.1,1.1A1.028,1.028,0,0,1,10.2,30.2Z" fill="#1d6f41" />
          <path id="Path_13" data-name="Path 13" d="M15.5,30.2h-.9a1.1,1.1,0,0,1,0-2.2h.9a1.1,1.1,0,0,1,0,2.2Z" fill="#1d6f41" />
          <path id="Path_14" data-name="Path 14" d="M21,19.3H20a1.11,1.11,0,0,1-1.1-1.1A1.2,1.2,0,0,1,20,17h1a1.11,1.11,0,0,1,1.1,1.1A1.2,1.2,0,0,1,21,19.3Z" fill="#1d6f41" />
          <path id="Path_15" data-name="Path 15" d="M17.9,35.6H6.2A3.222,3.222,0,0,1,3,32.4V5.9A3.222,3.222,0,0,1,6.2,2.7H23.7a3.222,3.222,0,0,1,3.2,3.2V16.7a1.1,1.1,0,1,1-2.2,0V5.9a.9.9,0,0,0-.9-.9H6.2a.9.9,0,0,0-.9.9V32.5a.9.9,0,0,0,.9.9H17.9a1.1,1.1,0,1,1,0,2.2Z" fill="#1d6f41" />
          <path id="Path_16" data-name="Path 16" d="M20.7,15H9.3a2.006,2.006,0,0,1-2-2V8.8a2.006,2.006,0,0,1,2-2H20.8a2.006,2.006,0,0,1,2,2v4.1A2.112,2.112,0,0,1,20.7,15ZM9.5,12.7h11V9H9.5Z" fill="#1d6f41" />
        </g>
      </g>
    </svg>
  );
}

export default Estimate2Icon;
