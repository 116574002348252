import { Redirect } from "react-router-dom";

//Icons
import IconBoxicons from "../pages/Icons/IconBoxicons";
import IconMaterialdesign from "../pages/Icons/IconMaterialdesign";
import IconDripicons from "../pages/Icons/IconDripicons";
import IconFontawesome from "../pages/Icons/IconFontawesome";

//Dashboard
import Dashboard from "../pages/Dashboard";

//Authentication pages
import Login from "src/pages/Authentication/Login";
import Logout from "src/pages/Authentication/Logout";
import Register from "src/pages/Authentication/Register";
import userProfile from "src/pages/Authentication/user-profile";
import Verify from "src/pages/Authentication/Verify";
import Error404 from "src/pages/Utility/Error404";
import Roles from "src/pages/Roles";
import RoleCreate from "src/pages/Roles/RoleCreate";
import RoleModify from "src/pages/Roles/RoleModify";
import UserCreate from "src/pages/Users/UserCreate";
import UserModify from "src/pages/Users/UserModify";
import Users from "src/pages/Users";
import Permissions from "src/pages/Roles/Permissions";
import CompanyProfile from "src/pages/company/CompanyProfile";
import Products from "src/pages/Products";
import ProductCreate from "src/pages/Products/ProductCreate";
import ProductModify from "src/pages/Products/ProductModify";
import Banks from "src/pages/Banks";
import BankCreate from "src/pages/Banks/BankCreate";
import BankModify from "src/pages/Banks/BankModify";
import Invoices from "src/pages/Invoices";
import InvoiceCreate from "src/pages/Invoices/InvoiceCreate";
import InvoiceModify from "src/pages/Invoices/InvoiceModify";
import InvoiceDetails from "src/pages/Invoices/InvoiceDetails";
import Customers from "src/pages/Customers";
import CustomerCreate from "src/pages/Customers/CustomerCreate";
import CustomerModify from "src/pages/Customers/CustomerModify";
import Estimations from "src/pages/Estimations";
import EstimationCreate from "src/pages/Estimations/EstimationCreate";
import EstimationModify from "src/pages/Estimations/EstimationModify";
import ForgetPassword from "src/pages/Authentication/ForgetPassword";
import ResetPassword from "src/pages/Authentication/ResetPassword";
import EstimationDetails from "src/pages/Estimations/EstimationDetails";
import EstimationView from "src/pages/Estimations/EstimationView";
import InvoiceView from "src/pages/Invoices/InvoiceView";
// import MicroFrontend from "src/components/MicroFrontend";

import { createBrowserHistory } from "history";
import ExpCategories from "src/pages/ExpCategories";
import ExpCategoryCreate from "src/pages/ExpCategories/ExpCategoryCreate";
import ExpItems from "src/pages/ExpItems";
import ExpItemCreate from "src/pages/ExpItems/ExpItemCreate";
import ExpItemModify from "src/pages/ExpItems/ExpItemModify";
import ExpCategoryModify from "src/pages/ExpCategories/ExpCategoryModify";
import Vendors from "src/pages/Vendors";
import VendorCreate from "src/pages/Vendors/VendorCreate";
import VendorModify from "src/pages/Vendors/VendorModify";
import Expenses from "src/pages/Expenses";
import ExpenseCreate from "src/pages/Expenses/ExpenseCreate";
import ExpenseModify from "src/pages/Expenses/ExpenseModify";
import PurchaseCreate from "src/pages/Expenses/PurchaseCreate";
import PurchaseModify from "src/pages/Expenses/PurchaseModify";

interface RouteProps {
  path: string;
  component: any;
  exact?: boolean;
}

const defaultHistory = createBrowserHistory();

/* const {
  REACT_APP_PAYROLL_HOST: payrollHost
} = process.env;


function Payroll({ history }: any) { 
  return <MicroFrontend history={history} host={payrollHost} name="Payroll" />;
} */

const userRoutes: Array<RouteProps> = [

  //User Profile
  { path: "/profile", component: userProfile },

  //dashboard
  { path: "/dashboard", component: Dashboard },

  //roles
  { path: "/roles", component: Roles },
  { path: "/role_add", component: RoleCreate },
  { path: "/role_edit/:id", component: RoleModify },
  { path: "/permissions/:role/:id", component: Permissions },

  //user
  { path: "/users", component: Users },
  { path: "/user_add", component: UserCreate },
  { path: "/user_edit/:id", component: UserModify },

  //Icons
  { path: "/icons-boxicons", component: IconBoxicons },
  { path: "/icons-materialdesign", component: IconMaterialdesign },
  { path: "/icons-dripicons", component: IconDripicons },
  { path: "/icons-fontawesome", component: IconFontawesome },

  //Company
  { path: "/company-profile", component: CompanyProfile },

  //products
  { path: "/products", component: Products },
  { path: "/product_add", component: ProductCreate },
  { path: "/product_edit/:id", component: ProductModify },

  //banks
  { path: "/banks", component: Banks },
  { path: "/bank_add", component: BankCreate },
  { path: "/bank_edit/:id", component: BankModify },

  //customers
  { path: "/customers", component: Customers },
  { path: "/customer_add", component: CustomerCreate },
  { path: "/customer_edit/:id", component: CustomerModify },

  //invoices
  { path: "/invoices", component: Invoices },
  { path: "/invoice_add", component: InvoiceCreate },
  { path: "/invoice_edit/:id", component: InvoiceModify },
  { path: "/invoice_details/:id", component: InvoiceDetails },

  //estimation
  { path: "/estimations", component: Estimations },
  { path: "/estimation_add", component: EstimationCreate },
  { path: "/estimation_edit/:id", component: EstimationModify },
  { path: "/estimation_details/:id", component: EstimationDetails },

  // { path: "/paroll", component: Payroll },

  //expenses categories
  { path: "/exp_categories", component: ExpCategories },
  { path: "/exp_category_add", component: ExpCategoryCreate },
  { path: "/exp_category_edit/:id", component: ExpCategoryModify },

  //expenses items
  { path: "/exp_items", component: ExpItems },
  { path: "/exp_item_add", component: ExpItemCreate },
  { path: "/exp_item_edit/:id", component: ExpItemModify },

  //expenses items
  { path: "/vendors", component: Vendors },
  { path: "/vendor_add", component: VendorCreate },
  { path: "/vendor_edit/:id", component: VendorModify },

   //expenses
   { path: "/expenses", component: Expenses },
   { path: "/expense_add", component: ExpenseCreate },
   { path: "/expense_edit/:id", component: ExpenseModify },
   { path: "/purchase_add", component: PurchaseCreate },
   { path: "/purchase_edit/:id", component: PurchaseModify },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/invoices" /> },
];

const authRoutes: Array<RouteProps> = [
  //Authentication pages
  { path: "/login", component: Login },
  { path: "/logout", component: Logout },
  { path: "/register", component: Register },
  { path: "/forgotpassword", component: ForgetPassword },
  { path: "/verify/:code", component: Verify },
  { path: "/change_password/:code", component: ResetPassword },
  { path: "/pages-404", component: Error404 },
  { path: "/estimation-view/:instanceId/:id", component: EstimationView },
  { path: "/invoice-view/:instanceId/:id", component: InvoiceView },
];

export { userRoutes, authRoutes };
