import React, { Component } from "react";
import MetaTags from "react-meta-tags";
import { Container, Collapse } from "reactstrap";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import { Row, Col, Card, CardBody, CardTitle, CardHeader, Button } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import { get, post } from "src/helpers/api_helper";
import classnames from "classnames";
import Accordion from "./Accordion";

interface PermissionParams {
    id: string;
    role: string;
}

type PermissionState = {
    id: string;
    role: string;
    col9: boolean;
    permissions: Array<any>;
};

interface LoopPermissions {
    permission_id: number;
    permission_name: string;
    actions: Array<any>;
}

interface LoopActions {
    id: number;
    role_id: string;
    module_permission_id: number;
    system_action_id: number;
    label: string;
    is_active: number;
}

class Permissions extends Component<RouteComponentProps<PermissionParams>, PermissionState> {
    constructor(props: any) {
        super(props);
        const { id, role } = this.props.match.params;
        this.state = {
            id: id,
            role: role,
            col9: true,
            permissions: []
        };
    }

    onChangehandler = (e: any) => {
        let name = e.target.name;
        let value = e.target.value;
        let data: any = {};
        data[name] = value;
        this.setState(data);
    }
    handleValidSubmit = (event: any, values: any) => {
        values["roleId"] = this.state.id;
        // console.log(values);
        const resp = post(process.env.REACT_APP_API_URL + '/api/role_save', values);
        this.props.history.push("/roles");
    }

    componentDidMount() {

        const sendGetRequest = async () => {
            try {

                const resp: any = await get(process.env.REACT_APP_API_URL + '/api/permission_settings/' + this.state.id);
                // console.log("resp");
                // console.log(resp.data);
                this.setState({ permissions: resp.data });

            } catch (err) {
                // Handle Error Here
                console.error(err);
            }
        };

        sendGetRequest();

    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Set Permissions</title>
                    </MetaTags>
                    <div className="container-fluid">
                        <Breadcrumbs title="Set Permissions" breadcrumbItem="Set Permissions" />

                        <Row>
                            <Col className="col-12">
                                <Card>

                                    <CardHeader>
                                        <h4 className="card-title">Role</h4>
                                        <p className="card-title-desc">{this.state.role}</p>
                                    </CardHeader>



                                    <CardBody>

                                        <AvForm onValidSubmit={this.handleValidSubmit}>
                                            {this.state.permissions.map((permission: LoopPermissions, index: number) => {
                                                let open:boolean = false; 
                                                if (!index)
                                                    open = true;                                                 
                                                return (
                                                    <Accordion index={index} permission={permission} open={open} />
                                                )
                                            }
                                            )}
                                        </AvForm>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div></div>
            </React.Fragment>
        )
    }
}

export default withRouter(Permissions);