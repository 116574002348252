import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import React, { useEffect,useState } from "react";

import { Row, Col, Alert, Container } from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link } from "react-router-dom";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// actions
import { userForgetPassword } from "../../store/actions";

// import images
import logo from "../../assets/images/logo_login.png";

//Import config
import config from "../../config";
// import CarouselPageNew from "./CarouselPageNew";
import CarouselPageNew from "./CarouselPageNew";
import axios from 'axios';

interface ForgetPasswordProps {
  history: object;
}

const ForgetPasswordPage = ({ history }: ForgetPasswordProps) => {
  // const dispatch = useDispatch();
  const appName = process.env.REACT_APP_NAME;  
  const [data, setData] = useState({ success : null, message : "" , data : { exist : null } }); 
  const [email, setEmail] = useState("");
  const [isProgress, setIsProgress] = useState(0); 
  const [isSending, setIsSending] = useState(0); 

    // const { forgetError, forgetSuccessMsg } = useSelector((state: any) => ({
  //   forgetError: state.forgetPassword.forgetError,
  //   forgetSuccessMsg: state.forgetPassword.forgetSuccessMsg,
  //   }));
    

  // handleValidSubmit
  // function handleValidSubmit(event: any, values: any) {
  //   dispatch(userForgetPassword(values, history));
  //   }

    // handleValidSubmit
  const handleValidSubmit = (values: any) => {    
    setIsProgress(1);
    const sendPostRequest = async () => {
        try {

          const resp = await axios.post(process.env.REACT_APP_API_URL + '/api/forgot_password', values);            
          setData(resp.data);       
          
          if (resp.data.success === false) {
            if (resp.data.data.exist === 2) {
              
            }
            setIsProgress(0);
          }

        } catch (err) {
            // Handle Error Here
            console.error(err);
        }
    };

    sendPostRequest(); 
    // dispatch(registerUser(values));
  };

  async function handleResend() {
    setIsSending(1);
    try {
      
      const resp = await axios.post(process.env.REACT_APP_API_URL + '/api/resend_email', {'email': email});            
      setData(resp.data);        
      
        setIsSending(0);
     

    } catch (err) {
        // Handle Error Here
        console.error(err);
    }
  }
 

  return (
    <React.Fragment>
      <MetaTags>
        <title>Forget Password | {appName}</title>
      </MetaTags>
      <div className="auth-page">
        <Container fluid className="p-0">
          <Row className="g-0">
          <CarouselPageNew />
            <Col lg={4} md={4} className="col-xxl-4">
              <div className="auth-full-page-content d-flex p-sm-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5 text-center">
                      <Link to="/" className="d-block auth-logo">
                      <img className="img-fluid" src={logo} alt="" />
{" "}
                      </Link>
                    </div>
                    { (data.success == true || data.data.exist === 1 ) ? (
                    <div className="auth-content my-auto">
                      <div className="text-center">
                        <div className="avatar-lg mx-auto">
                          <div className="avatar-title rounded-circle bg-light">
                            <i className="bx bx-mail-send h2 mb-0 text-primary"></i>
                          </div>
                        </div>
                        <div className="p-2 mt-4">
                          <h4>Verify your password reset link</h4>
                          {  data.data.exist === 2 ? (
                            <p dangerouslySetInnerHTML={{ __html: data.message }} ></p>
                          ) : (
                            <p>
                              We have sent you password reset email.
                              <span className="fw-bold">{email}</span>,
                              Please check it
                            </p>
                          )}


                          <div className="mt-4">
                            <Link
                              to="/"
                              className="btn btn-primary w-10"
                            >
                              Back to Home
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="mt-5 text-center">
                        <p className="text-muted mb-0">
                          Did't receive an email ?{" "}
                          <Link to="#" className="fw-semibold" onClick={handleResend}>
                            {" "}
                            Resend { isSending ? (
                              <div className="spinner-border spinner-border-sm" role="status">
                              <span className="sr-only">Loading...</span>
                            </div>
                            ) :null } {" "}
                          </Link>{" "}
                        </p>
                      </div>
                    </div>
                    ) : (
                    <div className="auth-content my-auto">
                      <div className="text-center">
                        <h5 className="mb-0">Reset Password</h5>
                        <p className="text-muted mt-2">
                          Reset Password with {process.env.REACT_APP_NAME}.
                        </p>
                      </div>

                      {/* {forgetError && forgetError ? ( */}
                        {/* <Alert color="danger" style={{ marginTop: "13px" }}> */}
                          {/* {forgetError} */}
                        {/* </Alert> */}
                      {/* ) : null}
                      {forgetSuccessMsg ? ( */}
                        {/* <Alert color="success" style={{ marginTop: "13px" }}> */}
                          {/* {forgetSuccessMsg} */}
                        {/* </Alert> */}
                      {/* ) : null} */}

                      {data.success === false ? (
                          <Alert color="danger"> 
                            <div dangerouslySetInnerHTML={{ __html: data.message }} ></div>                 
                          </Alert>
                          ) : null }
                       {data.success === true ? (
                          <Alert color="success"> 
                            <div dangerouslySetInnerHTML={{ __html: data.message }} ></div>                 
                          </Alert>
                          ) : null }

                      <AvForm
                        className="custom-form mt-4"
                        onValidSubmit={(e: any, v: any) => {
                          handleValidSubmit(v);
                        }}
                      >
                        <div className="mb-3">
                          <AvField
                            name="email"
                            label="Email"
                            className="form-control"
                            placeholder="Enter email"
                            type="email"
                            value={email}
                            onChange={(e:any) => setEmail(e.target.value)}
                            required
                          />
                        </div>
                        <div className="mb-3 mt-4">
                        {  isProgress === 1 ?
                              (
                              <button
                                className="btn blk-btn w-100 waves-effect waves-light"
                                type="submit" disabled={true}
                              >
                                <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>{" "}Submiting
                              </button>
                              ) : (
                              <button
                                className="btn blk-btn w-100 waves-effect waves-light"
                                type="submit"
                              >
                                Reset
                              </button>
                              )
                              }
                        </div>
                      </AvForm>

                      <div className="mt-5 text-center">
                        <p className="text-muted mb-0">
                          Remember It ?{" "}
                          <Link
                            to="/login"
                            className="fw-semibold"
                          >
                            {" "}
                            Sign In{" "}
                          </Link>{" "}
                        </p>
                      </div>
                    </div>
                     )}
                    <div className="mt-4 mt-md-5 text-center">
                      <p className="mb-0">
                      © {new Date().getFullYear()} {appName}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>            
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ForgetPasswordPage);
