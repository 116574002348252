import { inWords } from "./inWords";

export function padLeadingZeros(num: number, size: number) {
    var s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
  } 


  export function convertNumberToWords(value: number,currency: string, dtext: string): string {
    let arrAmount = value.toString().split('.');
    let words = '';    
    let num1 = Number(arrAmount[0]);
    let num2 = Number(arrAmount[1]);
    words +=  inWords(num1) + ` ${currency} `;
    if (arrAmount.length == 2 && num2 > 0) {
        words += ` And ` + inWords(num2) + ` ${dtext}`;
    } else {
        words += ` And Zero ${dtext}`;
    }
    words += ' Only';
    return words;
  }