import React from 'react';

const InvoiceIcon = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
    <g id="Group_594" data-name="Group 594" transform="translate(-1387 -463)">
      <rect id="Rectangle_111" data-name="Rectangle 111" width="50" height="50" transform="translate(1387 463)" fill="#ffb4b4" opacity="0"/>
      <g id="Menu" transform="translate(1387 463)">
        <path id="Path_443" data-name="Path 443" d="M41.92,0H8.08A8.08,8.08,0,0,0,0,8.08V41.92A8.08,8.08,0,0,0,8.08,50H41.92A8.08,8.08,0,0,0,50,41.92V8.08A8.08,8.08,0,0,0,41.92,0ZM45,41.92A3.08,3.08,0,0,1,41.92,45H8.08A3.08,3.08,0,0,1,5,41.92V8.08A3.08,3.08,0,0,1,8.08,5H41.92A3.08,3.08,0,0,1,45,8.08Z" fill="#111"/>
        <path id="Path_444" data-name="Path 444" d="M37.5,13h-25a2.5,2.5,0,0,0,0,5h25a2.5,2.5,0,0,0,0-5Zm0,10h-25a2.5,2.5,0,0,0,0,5h25a2.5,2.5,0,0,0,0-5Z" transform="translate(0 12)" fill="#111"/>
        <g id="Rectangle_90" data-name="Rectangle 90" transform="translate(10 9)" fill="none" stroke="#00c569" stroke-linecap="round" stroke-width="4">
          <rect width="29" height="12" rx="3" stroke="none"/>
          <rect x="2" y="2" width="25" height="8" rx="1" fill="none"/>
        </g>
      </g>
    </g>
  </svg>
  
  );
}

export default InvoiceIcon;
