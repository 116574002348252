import React from 'react';

const ExpenseIcon = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44">
            <g id="icons_add_expenses" transform="translate(37.3 33.2)">
                <g id="Rectangle_3" data-name="Rectangle 3" transform="translate(-37.3 -33.2)" fill="#fff" stroke="#707070" stroke-width="1" opacity="0">
                    <rect width="44" height="44" stroke="none" />
                    <rect x="0.5" y="0.5" width="43" height="43" fill="none" />
                </g>
                <g id="Group_4" data-name="Group 4" transform="translate(-35 -31)">
                    <path id="Path_17" data-name="Path 17" d="M28,36.2a8.3,8.3,0,1,1,8.3-8.3A8.28,8.28,0,0,1,28,36.2Zm0-14.4A6.2,6.2,0,1,0,34.2,28,6.231,6.231,0,0,0,28,21.8Z" fill="#1d6f41" />
                    <path id="Path_18" data-name="Path 18" d="M28,32.4a1.11,1.11,0,0,1-1.1-1.1V24.2a1.1,1.1,0,0,1,2.2,0v7.1A1.049,1.049,0,0,1,28,32.4Z" fill="#1d6f41" />
                    <path id="Path_19" data-name="Path 19" d="M31.6,28.9H24.5a1.1,1.1,0,0,1,0-2.2h7.1a1.11,1.11,0,0,1,1.1,1.1A1.182,1.182,0,0,1,31.6,28.9Z" fill="#1d6f41" />
                    <path id="Path_20" data-name="Path 20" d="M18.2,32H7a3.329,3.329,0,0,1-3.3-3.3V7.1A3.329,3.329,0,0,1,7,3.8H26.5a3.329,3.329,0,0,1,3.3,3.3V17.9a1.1,1.1,0,1,1-2.2,0V7.1a1.155,1.155,0,0,0-1.2-1.2H7A1.155,1.155,0,0,0,5.8,7.1V28.7A1.155,1.155,0,0,0,7,29.9H18.2A1.11,1.11,0,0,1,19.3,31,1.1,1.1,0,0,1,18.2,32Z" fill="#1d6f41" />
                    <path id="Path_21" data-name="Path 21" d="M23.8,11.6H9.4a1.1,1.1,0,0,1,0-2.2H23.8a1.1,1.1,0,0,1,0,2.2Z" fill="#1d6f41" />
                    <path id="Path_22" data-name="Path 22" d="M20.6,17.9H9.4a1.1,1.1,0,1,1,0-2.2H20.7a1.11,1.11,0,0,1,1.1,1.1A1.193,1.193,0,0,1,20.6,17.9Z" fill="#1d6f41" />
                    <path id="Path_23" data-name="Path 23" d="M16.2,25.3H9.4a1.1,1.1,0,1,1,0-2.2h6.8a1.11,1.11,0,0,1,1.1,1.1C17.2,24.9,16.8,25.3,16.2,25.3Z" fill="#1d6f41" />
                </g>
            </g>
        </svg>

    );
}

export default ExpenseIcon;
