import React from "react";
import MetaTags from "react-meta-tags";

//import Breadcrumbs
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Container, Row } from "reactstrap";
import InvoiceList from "../Invoices/InvoiceList";
import DashboardContent from "./DashboardContent";


const Dashboard = () => { 
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Dashboard</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Dashboard" breadcrumbItem="Dashboard" />
              
          <DashboardContent />
        
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
