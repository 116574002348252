import React from 'react';

const EstimateIcon = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
  <g id="Group_593" data-name="Group 593" transform="translate(-1316 -463)">
    <rect id="Rectangle_110" data-name="Rectangle 110" width="50" height="50" transform="translate(1316 463)" fill="#ffb4b4" opacity="0"/>
    <g id="Layer_2" data-name="Layer 2" transform="translate(1287 687)">
      <g id="Menu" transform="translate(29 -224)">
        <path id="Path_443" data-name="Path 443" d="M41.92,0H8.08A8.08,8.08,0,0,0,0,8.08V41.92A8.08,8.08,0,0,0,8.08,50H41.92A8.08,8.08,0,0,0,50,41.92V8.08A8.08,8.08,0,0,0,41.92,0ZM45,41.92A3.08,3.08,0,0,1,41.92,45H8.08A3.08,3.08,0,0,1,5,41.92V8.08A3.08,3.08,0,0,1,8.08,5H41.92A3.08,3.08,0,0,1,45,8.08Z" fill="#111"/>
        <path id="Path_444" data-name="Path 444" d="M37.5,12h-25a2.5,2.5,0,0,0,0,5h25a2.5,2.5,0,0,0,0-5Zm0,11h-25a2.5,2.5,0,0,0,0,5h25a2.5,2.5,0,0,0,0-5Z" fill="#111"/>
        <rect id="Rectangle_89" data-name="Rectangle 89" width="26" height="5" rx="2.5" transform="translate(10 34)" fill="#00c569"/>
      </g>
    </g>
  </g>
</svg>

  );
}

export default EstimateIcon;
